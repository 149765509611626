import {
  GET_DIGICERT_EXPIRED_DETAILS,
  GET_DIGICERT_EXPIRED_DETAILS_FAIL,
  GET_DIGICERT_EXPIRED_DETAILS_SUCCESS,
  USER_HAS_EXPIRED_CERTIFICATES,
  USER_HAS_EXPIRED_CERTIFICATES_FAIL,
  USER_HAS_EXPIRED_CERTIFICATES_SUCCESS,
  RENEW_DIGICERT_EXPIRED_DETAILS_FAIL,
  RENEW_DIGICERT_EXPIRED_DETAILS_START,
  RENEW_DIGICERT_EXPIRED_DETAILS_SUCCESS,
  RENEW_DIGICERT_EXPIRED_DETAILS_SIGNING,
  RENEW_DIGICERT_EXPIRED_DETAILS_DISMISS_WARNINGS,
} from '../actions/digicerts';

export const initialState = {
  data: null,
  error: null,
  loading: false,
  submissionLoading: false,
  signingInProgress: false,
  renewSubmissionComplete: false,
  userHasExpiredCertificates: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_DIGICERT_EXPIRED_DETAILS:
      return {
        ...state,
        ...{
          data: null,
          error: null,
          loading: true,
          submissionLoading: false,
          signingInProgress: false,
          renewSubmissionComplete: false,
        },
      };
    case GET_DIGICERT_EXPIRED_DETAILS_SUCCESS:
      return {
        ...state,
        ...{
          error: null,
          loading: false,
          data: action.payload,
        },
      };
    case GET_DIGICERT_EXPIRED_DETAILS_FAIL:
      return {
        ...state,
        ...{
          data: null,
          error: action.error,
          loading: false,
        },
      };
    case USER_HAS_EXPIRED_CERTIFICATES:
      return {
        ...state,
        ...{
          error: null,
          userHasExpiredCertificates: null,
        },
      };
    case USER_HAS_EXPIRED_CERTIFICATES_SUCCESS:
      return {
        ...state,
        ...{
          error: null,
          userHasExpiredCertificates: action.payload,
        },
      };
    case USER_HAS_EXPIRED_CERTIFICATES_FAIL:
      return {
        ...state,
        ...{
          error: action.error,
          userHasExpiredCertificates: false,
        },
      };
    case RENEW_DIGICERT_EXPIRED_DETAILS_SIGNING:
      return {
        ...state,
        ...{
          error: null,
          submissionLoading: true,
          signingInProgress: true,
          renewSubmissionComplete: false,
        },
      };
    case RENEW_DIGICERT_EXPIRED_DETAILS_START:
      return {
        ...state,
        ...{
          error: null,
          submissionLoading: true,
          signingInProgress: false,
          renewSubmissionComplete: false,
        },
      };
    case RENEW_DIGICERT_EXPIRED_DETAILS_SUCCESS:
      return {
        ...state,
        ...{
          error: null,
          submissionLoading: false,
          signingInProgress: false,
          renewSubmissionComplete: true,
        },
      };
    case RENEW_DIGICERT_EXPIRED_DETAILS_FAIL:
      return {
        ...state,
        ...{
          error: action.error,
          submissionLoading: false,
          signingInProgress: false,
          renewSubmissionComplete: true,
        },
      };
    case RENEW_DIGICERT_EXPIRED_DETAILS_DISMISS_WARNINGS:
      return {
        ...state,
        ...{
          error: null,
          submissionLoading: false,
          signingInProgress: false,
          renewSubmissionComplete: false,
        },
      };
    default:
      return state;
  }
};
