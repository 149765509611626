/* istanbul ignore file */
import React from 'react';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import withStyles from '@material-ui/core/styles/withStyles';
import Segment from 'segment-js/dist/segment';
import { easeElasticOut, easeBounceOut, easeElasticIn } from 'd3-ease';

const beginAC = 80;
const endAC = 320;
const beginB = 80;
const endB = 320;

function inAC2(s) {
  s.draw('100% - 545', '100% - 305', 0.6, {
    easing: easeElasticOut,
  });
}

function inAC(s) {
  s.draw('80% - 240', '80%', 0.3, {
    delay: 0.1,
    callback() {
      inAC2(s);
    },
  });
}

function inB2(s) {
  s.draw(beginB + 120, endB - 120, 0.3, {
    easing: easeBounceOut,
  });
}

function inB(s) {
  s.draw(beginB - 60, endB + 60, 0.1, {
    callback() {
      inB2(s);
    },
  });
}

/* Out animations (to burger icon) */
function outAC3(s) {
  s.draw(beginAC, endAC, 0.7, {
    easing: easeElasticOut,
  });
}

function outAC2(s) {
  s.draw('20% - 240', '20%', 0.3, {
    callback() {
      outAC3(s);
    },
  });
}

function outAC(s) {
  s.draw('90% - 240', '90%', 0.1, {
    easing: easeElasticIn,
    callback() {
      outAC2(s);
    },
  });
}

function outB(s) {
  s.draw(beginB, endB, 0.7, {
    delay: 0.1,
    easing: easeElasticOut,
  });
}

class AnimatedBurgerButton extends React.Component {
  state = {
    scaled: false,
  };

  pathD = null;

  pathE = null;

  pathF = null;

  componentDidMount() {
    this.segmentD = new Segment(this.pathD, beginAC, endAC);
    this.segmentE = new Segment(this.pathE, beginB, endB);
    this.segmentF = new Segment(this.pathF, beginAC, endAC);
  }

  componentDidUpdate(prevProps) {
    const { value: prevValue } = prevProps;
    const { value } = this.props;

    if (prevValue !== undefined && prevValue !== value) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ scaled: true });

      if (value) {
        inAC(this.segmentD);
        inB(this.segmentE);
        inAC(this.segmentF);
      } else {
        outAC(this.segmentD);
        outB(this.segmentE);
        outAC(this.segmentF);
      }

      setTimeout(() => this.setState({ scaled: false }), 450);
    }
  }

  handleClick = () => {
    const { onToggle } = this.props;
    onToggle();
  };

  render() {
    const { scaled } = this.state;
    const { classes } = this.props;

    return (
      <IconButton
        aria-label="Open drawer"
        onClick={this.handleClick}
        className={[classes.menuButton, scaled ? classes.menuButtonScaled : ''].join(' ').trim()}
        classes={{
          label: classes.buttonLabel,
        }}
        id="mobile-menu-button"
      >
        <svg>
          <path
            ref={(ref) => {
              this.pathD = ref;
            }}
            d="M 300 400 L 700 400 C 900 400 900 750 600 850 A 400 400 0 0 1 200 200 L 800 800"
            style={{
              strokeDashoffset: 5803.15,
              strokeDasharray: '2901.57, 2981.57, 240',
            }}
          />
          <path
            ref={(ref) => {
              this.pathE = ref;
            }}
            d="M 300 500 L 700 500"
            style={{
              strokeDashoffset: 800,
              strokeDasharray: '400, 480, 240',
            }}
          />
          <path
            ref={(ref) => {
              this.pathF = ref;
            }}
            d="M 700 600 L 300 600 C 100 600 100 200 400 150 A 400 380 0 1 1 200 800 L 800 200"
            style={{
              strokeDashoffset: 6993.11,
              strokeDasharray: '3496.56, 3576.56, 240',
            }}
          />
        </svg>
      </IconButton>
    );
  }
}

AnimatedBurgerButton.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  onToggle: PropTypes.func.isRequired,
  value: PropTypes.bool.isRequired,
};

const style = theme => ({
  buttonLabel: {
    width: 25,
    height: 25,
  },
  menuButton: {
    '& svg': {
      position: 'absolute',
      top: '-11px',
      left: '-11px',
      width: 1000,
      height: 1000,
      transform: 'scale(0.07)',
      transformOrigin: '0 0',
    },
    '& path': {
      stroke: theme.palette.common.white,
      strokeWidth: 45,
      strokeLinecap: 'round',
      strokeLinejoin: 'round',
      fill: 'transparent',
    },
  },
  menuButtonScaled: {
    transform: 'scale(0.5)',
    left: 5,
  },
});

export default withStyles(style, { withTheme: true })(AnimatedBurgerButton);
