import CryptoJS from 'crypto-js';

const iterations = 10000;
const keySize = 128 / 32;
const passPhrase = '23$yOV9+Rt34*!/zPA';
const iv = 'F27D5C9927726BCEFE7510B1BDD3D137';
const salt = '3FF2EC019C627B945225DEBAD71A01B6985FE84C95A70EB132882F88C0A59A55';

const generateKey = () => {
  const key = CryptoJS.PBKDF2(
    passPhrase,
    CryptoJS.enc.Hex.parse(salt),
    { keySize, iterations },
  );
  return key;
};

const encrypt = (plainText) => {
  const key = generateKey();
  const encrypted = CryptoJS.AES.encrypt(
    plainText,
    key,
    { iv: CryptoJS.enc.Hex.parse(iv) },
  );
  return encrypted.ciphertext.toString(CryptoJS.enc.Base64);
};

const decrypt = (cipherText) => {
  const key = generateKey();
  const cipherParams = CryptoJS.lib.CipherParams.create({
    ciphertext: CryptoJS.enc.Base64.parse(cipherText),
  });
  const decrypted = CryptoJS.AES.decrypt(
    cipherParams,
    key,
    { iv: CryptoJS.enc.Hex.parse(iv) },
  );
  return decrypted.toString(CryptoJS.enc.Utf8);
};

export {
  encrypt,
  decrypt,
};
