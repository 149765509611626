export const GET_PEXA_KEY_INVITATIONS = 'GET_PEXA_KEY_INVITATIONS';
export const GET_PEXA_KEY_INVITATIONS_SUCCESS = 'GET_PEXA_KEY_INVITATIONS_SUCCESS';
export const GET_PEXA_KEY_INVITATIONS_FAIL = 'GET_PEXA_KEY_INVITATIONS_FAIL';


export function getPexaKeyInvitations() {
  return {
    types: [
      GET_PEXA_KEY_INVITATIONS,
      GET_PEXA_KEY_INVITATIONS_SUCCESS,
      GET_PEXA_KEY_INVITATIONS_FAIL,
    ],
    payload: {
      client: 'bffClient',
      request: {
        url: '/key/invitations',
        method: 'get',
      },
    },
  };
}
