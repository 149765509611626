export const USER_HAS_EXPIRED_CERTIFICATES = 'USER_HAS_EXPIRED_CERTIFICATES';
export const USER_HAS_EXPIRED_CERTIFICATES_FAIL = 'USER_HAS_EXPIRED_CERTIFICATES_FAIL';
export const USER_HAS_EXPIRED_CERTIFICATES_SUCCESS = 'USER_HAS_EXPIRED_CERTIFICATES_SUCCESS';

export const GET_DIGICERT_EXPIRED_DETAILS = 'GET_DIGICERT_EXPIRED_DETAILS';
export const GET_DIGICERT_EXPIRED_DETAILS_FAIL = 'GET_DIGICERT_EXPIRED_DETAILS_FAIL';
export const GET_DIGICERT_EXPIRED_DETAILS_SUCCESS = 'GET_DIGICERT_EXPIRED_DETAILS_SUCCESS';

export const RENEW_DIGICERT_EXPIRED_DETAILS_FAIL = 'RENEW_DIGICERT_EXPIRED_DETAILS_FAIL';
export const RENEW_DIGICERT_EXPIRED_DETAILS_START = 'RENEW_DIGICERT_EXPIRED_DETAILS_START';
export const RENEW_DIGICERT_EXPIRED_DETAILS_SUCCESS = 'RENEW_DIGICERT_EXPIRED_DETAILS_SUCCESS';
export const RENEW_DIGICERT_EXPIRED_DETAILS_SIGNING = 'RENEW_DIGICERT_EXPIRED_DETAILS_SIGNING';
export const RENEW_DIGICERT_EXPIRED_DETAILS_DISMISS_WARNINGS = 'RENEW_DIGICERT_EXPIRED_DETAILS_DISMISS_WARNINGS';

export function getDetails() {
  return {
    types: [
      GET_DIGICERT_EXPIRED_DETAILS,
      GET_DIGICERT_EXPIRED_DETAILS_SUCCESS,
      GET_DIGICERT_EXPIRED_DETAILS_FAIL],
    payload: {
      client: 'digicertsClient',
      request: {
        url: '/digicerts/expiring/details',
      },
    },
  };
}

export function hasUserExpiredCertificates() {
  return {
    types: [
      USER_HAS_EXPIRED_CERTIFICATES,
      USER_HAS_EXPIRED_CERTIFICATES_SUCCESS,
      USER_HAS_EXPIRED_CERTIFICATES_FAIL],
    payload: {
      client: 'digicertsClient',
      request: {
        url: '/digicerts/expiring/user',
      },
    },
  };
}

export function startSubmission() {
  return {
    type: RENEW_DIGICERT_EXPIRED_DETAILS_SIGNING,
  };
}

export function dismissSubmissionWarnings() {
  return {
    type: RENEW_DIGICERT_EXPIRED_DETAILS_DISMISS_WARNINGS,
  };
}

export function formSubmission(form) {
  return {
    types: [
      RENEW_DIGICERT_EXPIRED_DETAILS_START,
      RENEW_DIGICERT_EXPIRED_DETAILS_SUCCESS,
      RENEW_DIGICERT_EXPIRED_DETAILS_FAIL],
    payload: {
      client: 'digicertsClient',
      request: {
        data: form,
        method: 'post',
        url: '/digicerts/renew',
      },
    },
  };
}
