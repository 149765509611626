/* istanbul ignore file */
import { cloneDeep } from 'lodash';

export const PUT_MY_SUMMARY = 'PUT_MY_SUMMARY';
export const PUT_MY_SUMMARY_FAIL = 'PUT_MY_SUMMARY_FAIL';
export const PUT_MY_SUMMARY_SUCCESS = 'PUT_MY_SUMMARY_SUCCESS';
export const RESET_MY_SUMMARY_STATE = 'RESET_MY_SUMMARY_STATE';

export function setUserPreferences(widgetData) {
  return {
    types: [PUT_MY_SUMMARY, PUT_MY_SUMMARY_SUCCESS, PUT_MY_SUMMARY_FAIL],
    payload: {
      client: 'bffClient',
      request: {
        method: 'put',
        url: '/mysummary',
        data: widgetData,
      },
    },
  };
}

export function resetMySummaryState(origWidgetData) {
  return {
    type: RESET_MY_SUMMARY_STATE,
    payload: cloneDeep(origWidgetData),
  };
}
