export const GET_INVITATIONS = 'GET_INVITATIONS';
export const GET_INVITATIONS_SUCCESS = 'GET_INVITATIONS_SUCCESS';
export const GET_INVITATIONS_FAIL = 'GET_INVITATIONS_FAIL';

export function getInvitations() {
  return {
    types: [GET_INVITATIONS, GET_INVITATIONS_SUCCESS, GET_INVITATIONS_FAIL],
    payload: {
      request: {
        url: '/summary?invitations',
      },
    },
  };
}
