import Cookies from 'js-cookie';

import EnvValues from '../conf/config';
import { decrypt } from './encryptionUtils';

const LOGOUT_PATH = '/logout';
const cookieName = 'PP_V2_PROFILE';
const WEB_PROFILE_PATH = '/webprofile';

const authenticated = () => {
  const cookie = Cookies.get(cookieName);

  if (cookie || EnvValues.BY_PASS_AUTH) {
    return true;
  }

  return null;
};

const getUserInfo = () => {
  const cookie = Cookies.get(cookieName);

  const userInfo = {
    abn: '76006212326',
    displayName: 'Local Dev',
    fullName: 'emily jones hua',
    partyName: 'Local Conveyancer',
    accessToDigitalCertificate: true,
    accessToMattersMarketPlace: true,
    accessToMattersContract: true,
    accessToPretotype: true,
    username: 'emilyjoneshua@pexa.com.au',
    jurisdictions: 'NSW,VIC',
    subscriberId: '683',
    subscriberRating: '20,6.7',
  };

  const tenOrderSurvey = {
    matter_reference: localStorage.getItem('matterReference'),
    marketplace_date_order_complete: localStorage.getItem('dateComplete'),
    marketplace_date_order_placed: localStorage.getItem('datePlaced'),
    order_jurisdiction: localStorage.getItem('jurisdiction'),
    member_total_orders: localStorage.getItem('totalOrders') || 0,
    est_turnaround_time: localStorage.getItem('eta'),
    marketplace_provider_name: localStorage.getItem('providerName'),
    marketplace_product_name: localStorage.getItem('productName'),
    marketplace_order_id: localStorage.getItem('orderId'),
  };

  if (cookie) {
    const cookieUnencrypted = decrypt(cookie);
    if (cookieUnencrypted) {
      const cookieValue = cookieUnencrypted.split('#');

      userInfo.displayName = cookieValue[0].replace('displayName:', '');
      userInfo.partyName = cookieValue[1].replace('subscriberName:', '');
      userInfo.accessToDigitalCertificate = (cookieValue[2].replace('accessToDigitalCertificate:', '') === 'true') || false;
      userInfo.accessToMattersMarketPlace = (cookieValue[3].replace('accessToMattersMarketPlace:', '') === 'true') || false;
      userInfo.fullName = cookieValue[4].replace('fullName:', '');
      userInfo.username = cookieValue[5].replace('username:', '');
      userInfo.abn = cookieValue[6].replace('abn:', '');
      userInfo.jurisdictions = cookieValue[7].replace('jurisdictions:', '');
      userInfo.accessToMattersContract = (cookieValue[8].replace('accessToMattersContract:', '') === 'true') || false;
      userInfo.subscriberRating = cookieValue[9].replace('subscriberRating:', '');
      userInfo.subscriberId = cookieValue[10].replace('subscriberId:', '');
      userInfo.accessToPretotype = (cookieValue[11].replace('accessToPretotype:', '') === 'true') || false;
    }
  }

  window.pxdata = {
    session: {
      user_name: userInfo.fullName,
      email_address: userInfo.username,
      subscriber_name: userInfo.partyName,
      subscriber_id: userInfo.subscriberId,
      tenOrderSurvey,
    },
  };

  return userInfo;
};

/* istanbul ignore next */
const getWebProfileEndpoint = (appRoute = '') => {
  const routeTo = `?appRoute=${encodeURIComponent(appRoute)}`;
  return EnvValues.PROFILE_API_ENDPOINT + WEB_PROFILE_PATH + routeTo;
};

/* istanbul ignore next */
const getLogoutEndpoint = (appRoute = '') => {
  const routeTo = `?appRoute=${encodeURIComponent(appRoute)}`;
  return EnvValues.PROFILE_API_ENDPOINT + LOGOUT_PATH + routeTo;
};

/* istanbul ignore next */
const removeCookie = () => {
  Cookies.remove(cookieName);
};

export {
  getUserInfo,
  removeCookie,
  authenticated,
  getLogoutEndpoint,
  getWebProfileEndpoint,
};
