import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import {
  Typography, Button, Link, Grid,
} from '@material-ui/core';

import error400Img from './img/error_400.png';
import error401Img from './img/error_401.png';
import error403Img from './img/error_403.png';
import error404Img from './img/error_404.png';
import error500Img from './img/error_500.png';
import error501Img from './img/error_501.png';
import error502Img from './img/error_502.png';
import error503Img from './img/error_503.png';
import error504Img from './img/error_504.png';
import EnvValues from '../conf/config';

export const errorCodes = [{
  code: 400,
  title:
  <Typography variant="h1" color="primary">
    Sorry, our server couldn&apos;t understand that request.
  </Typography>,
  details:
  <Typography component="div">
    Your client provided a request our server couldn&apos;t understand. Try
    reloading the page.
    <div>Error code: 400</div>
  </Typography>,
  img: error400Img,
  cta: 'Try again',
  ctaLink: '.',
}, {
  code: 401,
  title:
  <Typography variant="h1" color="primary">
    Sorry, something&apos;s not adding up.
  </Typography>,
  details:
  <Typography component="div">
    We couldn&apos;t verify your credentials. Check your details and try again.
    <div>Error code: 401</div>
  </Typography>,
  img: error401Img,
  cta: 'Go to login',
  ctaLink: `${EnvValues.API_ENDPOINT}/logout`,
}, {
  code: 403,
  title:
  <Typography variant="h1" color="primary">
    Sorry, it looks like you don&apos;t have permission to access this content.
  </Typography>,
  details:
  <Typography component="div">
    Check the link is correct and try again.
    <div>Error code: 403</div>
  </Typography>,
  img: error403Img,
  cta: 'Go to login',
  ctaLink: `${EnvValues.API_ENDPOINT}/logout`,
}, {
  code: 404,
  title:
  <Typography variant="h1" color="primary">
    Sorry, we can&apos;t find the page you&apos;re looking for.
  </Typography>,
  details:
  <Typography component="div">
    Check the link is correct and try again.
    <div>Error code: 404</div>
  </Typography>,
  img: error404Img,
  cta: 'Try again',
  ctaLink: '.',
}, {
  code: 500,
  title:
  <Typography variant="h1" color="primary">
    We&apos;ve encountered an error
  </Typography>,
  details:
  <Typography component="div">
    Try reloading the page.
    <div>Error code: 500</div>
  </Typography>,
  img: error500Img,
  cta: 'Try again',
  ctaLink: '.',
}, {
  code: 501,
  title:
  <Typography variant="h1" color="primary">
    We&apos;ve encountered an error
  </Typography>,
  details:
  <Typography component="div">
    We are urgently working on a fix. Try reloading the page.
    <div>Error code: 501</div>
  </Typography>,
  img: error501Img,
  cta: 'Try again',
  ctaLink: '.',
}, {
  code: 502,
  title:
  <Typography variant="h1" color="primary">
    We&apos;ve encountered an error
  </Typography>,
  details:
  <Typography component="div">
    We are urgently working on a fix. Try reloading the page.
    <div>Error code: 502</div>
  </Typography>,
  img: error502Img,
  cta: 'Try again',
  ctaLink: '.',
}, {
  code: 503,
  title:
  <Typography variant="h1" color="primary">
    We&apos;ve encountered an error
  </Typography>,
  details:
  <Typography component="div">
    We are urgently working on a fix. Try reloading the page.
    <div>Error code: 503</div>
  </Typography>,
  img: error503Img,
  cta: 'Try again',
  ctaLink: '.',
}, {
  code: 504,
  title:
  <Typography variant="h1" color="primary">
    We&apos;ve encountered an error
  </Typography>,
  details:
  <Typography component="div">
    We are urgently working on a fix. Try reloading the page.
    <div>Error code: 504</div>
  </Typography>,
  img: error504Img,
  cta: 'Try again',
  ctaLink: '.',
}];

const GenericError = ({ classes, error }) => (
  <Grid>
    <div className={classes.container}>
      <div />
      <div>
        <div className={classes.title}>{error.title}</div>
        <div className={classes.details}>{error.details}</div>
        {error.cta && (
        <Button variant="contained" color="secondary" href={error.ctaLink}>
            {error.cta}
        </Button>
        )}
      </div>
      <img className={classes.mainImg} alt="Error" src={error.img} />
      <div />
    </div>
    <Link
      target="_blank"
      rel="noopener noreferrer"
      className={classes.learnMore}
      href="https://www.pexa.com.au/help/s/article/PEXA-System-Error-Messages"
    >
      <Typography color="secondary">Need support?</Typography>
    </Link>
  </Grid>
);

GenericError.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  error: PropTypes.shape({}),
};

GenericError.defaultProps = {
  error: errorCodes[4],
};

const style = theme => ({
  container: {
    display: 'grid',
    gridTemplateColumns: '15% 35% 35% 15%',
    gridGap: '1rem',
    alignItems: 'center',
    maxWidth: 'auto',
    textAlign: 'left',
  },
  mainImg: {
    width: '100%',
    height: 'auto',
    margin: '20px auto',
  },
  title: {
    fontSize: 40,
    alignItems: 'center',
    textAlign: 'left',
    margin: '20px auto',
  },
  details: {
    margin: '0 auto',
    textAlign: 'left',
    marginBottom: 20,
    '& ul': {
      listStyle: 'none',
      textAlign: 'left',
      margin: 18,
    },
    '& li': {
      '& p': {
        marginTop: -20,
      },
      '&:before': {
        content: '">"',
        fontWeight: 800,
        color: theme.palette.secondary.main,
        display: 'inline-block',
        width: '1em',
        marginLeft: '-1em',
      },
    },
    '& div': {
      marginTop: 20,
    },
  },
  learnMore: {
    textAlign: 'center',
    textDecoration: 'underline',
    color: theme.palette.secondary.main,
    '& p': {
      marginTop: 20,
      fontWeight: 'bold',
    },
  },
});

export default withStyles(style, { withTheme: true })(GenericError);
