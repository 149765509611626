import {
  GET_PEXA_KEY_INVITATIONS,
  GET_PEXA_KEY_INVITATIONS_SUCCESS,
  GET_PEXA_KEY_INVITATIONS_FAIL,
} from '../actions/pexa-key';

export const initialState = {
  data: null,
  loading: false,
  error: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_PEXA_KEY_INVITATIONS:
      return {
        ...state,
        ...{
          data: null,
          error: null,
          loading: true,
        },
      };
    case GET_PEXA_KEY_INVITATIONS_SUCCESS:
      return {
        ...state,
        ...{
          data: action.payload,
          error: null,
          loading: false,
        },
      };
    case GET_PEXA_KEY_INVITATIONS_FAIL:
      return {
        ...state,
        ...{
          data: null,
          error: action.error,
          loading: false,
        },
      };
    default:
      return state;
  }
};
