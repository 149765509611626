export const GET_MY_WORKSPACES = 'GET_MY_WORKSPACES';
export const GET_MY_WORKSPACES_SUCCESS = 'GET_MY_WORKSPACES_SUCCESS';
export const GET_MY_WORKSPACES_FAIL = 'GET_MY_WORKSPACES_FAIL';

export function getMyWorkspaces() {
  return {
    types: [GET_MY_WORKSPACES, GET_MY_WORKSPACES_SUCCESS, GET_MY_WORKSPACES_FAIL],
    payload: {
      request: {
        url: '/summary?my_workspaces',
      },
    },
  };
}
