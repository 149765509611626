/* istanbul ignore file */
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import withStyles from '@material-ui/core/styles/withStyles';
import BallotOutlinedIcon from '@material-ui/icons/BallotOutlined';
import AssignmentIndOutlinedIcon from '@material-ui/icons/AssignmentIndOutlined';
import SettingsInputHdmiOutlinedIcon from '@material-ui/icons/SettingsInputHdmiOutlined';
import VpnKeyIcon from '@material-ui/icons/VpnKey';

import {
  Navigate, Route, Routes,
} from 'react-router-dom';
import { HistoryRouter as Router } from 'redux-first-history/rr6';
import { history } from '../store';

import Header from '../components/header';
import Nav from '../components/nav';
import ErrorBoundary, { getErrorComponent } from '../errorPages';

import logoHeader from '../assets/img/PEXA-logo-header.svg';
import logoWorkspaces from '../assets/img/PEXA-workspaces.svg';
import logoPlus from '../assets/img/PEXA-plus.svg';
import logoProjects from '../assets/img/PEXA-projects.svg';
import logoHelpCentre from '../assets/img/PEXA-help-center.svg';

import asyncComponent from '../utils/asyncComponent';

import ProtectedRoute from './protectedRoute';
import { authenticated, getUserInfo } from './security';
import EnvValues from '../conf/config';

const Summary = asyncComponent(() => import('../summary/container'));
const DigiCerts = asyncComponent(() => import('../digital-certificates/container'));
const AccountManagement = asyncComponent(() => import('../account-management'));
const PexaKey = asyncComponent(() => import('../pexa-key/container'));

class App extends Component {
  constructor() {
    super();

    this.userInfo = getUserInfo();

    this.menuItems = [
      {
        menuText: 'Summary',
        menuIcon: <BallotOutlinedIcon />,
        linkTo: '/summary',
      },
    ];

    this.header = {
      logoSrc: logoHeader,
      appName: 'PEXA Plus',
      logoHlp: logoHelpCentre,
      hlpUrl: EnvValues.HELP_CENTRE_URL,
      hlpAlt: 'Help centre',
      displayName: this.userInfo.displayName,
      partyName: this.userInfo.partyName,
      subscriberRating: this.userInfo.subscriberRating,
      otherApps: [
        {
          appName: 'Workspaces',
          logoSrc: logoWorkspaces,
          appURL: EnvValues.WORKSPACES_URL,
        },
        {
          appName: 'PEXA Plus',
          logoSrc: logoPlus,
          /* eslint-disable-next-line */
          appURL: '#',
          current: true,
        },
        {
          appName: 'PEXA Projects',
          logoSrc: logoProjects,
          appURL: EnvValues.PROJECTS_URL,
        },
        {
          appName: 'Help centre',
          logoSrc: logoHelpCentre,
          appURL: EnvValues.HELP_CENTRE_URL,
        },
      ],
    };

    this.menuItems.push({
      isNew: true,
      menuText: 'PEXA Key',
      menuIcon: <VpnKeyIcon />,
      linkTo: '/pexa-key',
    }, {
      menuText: 'Manage Account',
      menuIcon: <AssignmentIndOutlinedIcon />,
      linkTo: '/account-management',
    });

    if (this.userInfo.accessToDigitalCertificate) {
      this.menuItems.push({
        menuText: 'Digital Certificates',
        menuIcon: <SettingsInputHdmiOutlinedIcon />,
        linkTo: '/digicerts',
        notification: true,
      });
    }
  }

  render() {
    const { classes } = this.props;
    return (
      <Router history={history}>
        <Fragment>
          <Header {...this.header} />
          <div className={authenticated() ? classes.mainBody : classes.mainBodyPublic}>
            <div className={classes.mainBodyContent}>
              <ErrorBoundary>
                <Routes>
                  <Route path="/" element={<Navigate to="/summary" />} />
                  <Route path="/summary" element={<ProtectedRoute><Summary /></ProtectedRoute>} />
                  <Route path="/pexa-key" element={<ProtectedRoute><PexaKey /></ProtectedRoute>} />
                  <Route path="/account-management" element={<ProtectedRoute><AccountManagement /></ProtectedRoute>} />
                  <Route path="/digicerts" element={<ProtectedRoute hasAccess={this.userInfo.accessToDigitalCertificate}><DigiCerts /></ProtectedRoute>} />
                  <Route path="/forbidden" element={getErrorComponent(403)} />
                  <Route path="*" element={getErrorComponent(404)} />
                </Routes>
              </ErrorBoundary>
            </div>
            <Nav menuItems={this.menuItems} />
          </div>
        </Fragment>
      </Router>
    );
  }
}

App.propTypes = {
  classes: PropTypes.shape({}).isRequired,
};

const style = theme => ({
  mainBody: {
    minHeight: '100%',
    position: 'relative',
    paddingTop: 67,
    marginLeft: 115,
    [theme.breakpoints.down('md')]: {
      marginLeft: 0,
      paddingTop: 117,
    },
  },
  mainBodyPublic: {
    minHeight: '100%',
    position: 'relative',
  },
  mainBodyContent: {
    [theme.breakpoints.down('md')]: {
      margin: 0,
    },
    overflow: 'hidden',
  },
});

export default withStyles(style)(App);
