import { LOCATION_CHANGE } from 'redux-first-history';

const LOAD_ERROR_PAGE = 'LOAD_ERROR_PAGE';

export const initialState = {
  clearOnNext: false,
  error: null,
  hasError: false,
  statusCode: null,
};

export const loadErrorPage = ({ error } = {}) => {
  if (!error) {
    throw new Error('error is required');
  }

  return {
    type: LOAD_ERROR_PAGE,
    error,
  };
};

export default function errorPage(state = initialState, action) {
  switch (action.type) {
    case LOCATION_CHANGE: {
      if (state.clearOnNext) {
        return initialState;
      }

      return {
        ...state,
        clearOnNext: true,
      };
    }

    case LOAD_ERROR_PAGE: {
      let statusCode = 500;
      if (action.error && action.error.response
        && action.error.response.status
      ) {
        statusCode = action.error.response.status;
      }

      return {
        ...state,
        error: action.error,
        hasError: true,
        statusCode,
      };
    }
    default:
      return state;
  }
}
