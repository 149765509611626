import { createTheme } from '@material-ui/core/styles';
// https://material-ui.com/style/typography#migration-to-typography-v2
// display4 => h1
// display3 => h2
// display2 => h3
// display1 => h4
// headline => h5
// title => h6
// subheading => subtitle1
// body2 => body1
// body1 (default) => body2 (default)


// Create a default theme to get the default.shadows
const defaults = createTheme();
defaults.shadows.splice(1, 1, [
  '0 1px 8px 0 rgba(0,0,0,0.10), 0 1px 7px 0 rgba(0,0,0,0.05), 0 3px 1px -3px rgba(0,0,0,0.1)',
]);

const PexaMuiTheme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 500,
      md: 769,
      lg: 1025,
      vl: 1500,
      xl: 1920,
    },
  },
  typography: {
    fontFamily: "'Quicksand', Arial, sans-serif",
    fontSize: 16,
    fontWeightLight: 400,
    fontWeightRegular: 500,
    fontWeightMedium: 600,
    fontWeightHeavy: 900,

    h1: {
      fontSize: '2rem',
      lineHeight: 1.2,
      fontFamily: "'Libre-franklin', Arial, sans-serif",
    },
    h2: {
      fontSize: '1.25rem',
      fontWeight: 'bold',
      lineHeight: 1.26,
    },
    h3: {
      fontSize: '1.09375rem',
      fontWeight: 'bold',
      lineHeight: 1.26,
    },
    h4: {
      fontSize: '1rem',
      fontWeight: 500,
      lineHeight: 1.38,
    },
    h5: {
      fontSize: '0.9375rem',
      fontWeight: 500,
      lineHeight: 1.33,
    },
    h6: {
      fontSize: '0.875rem',
      fontWeight: 500,
      lineHeight: 1.21,
    },
    subtitle1: {
      fontSize: '0.8rem',
      fontWeight: 500,
      lineHeight: 1.28,
    },
    subtitle2: {
      fontSize: '0.6875rem',
      fontWeight: 500,
      lineHeight: 1.18,
    },
    body1: {
      color: '#767676',
      fontSize: '1rem',
    },
  },
  palette: {
    background: {
      default: '#f3f3f4',
    },
    primary: {
      light: '#4c4d68',
      main: '#353755',
      dark: '#1f2142',
    },
    secondary: {
      light: '#d708b3',
      main: '#a63288',
      dark: '#c31ca7',
    },

    // 4 state colours
    error: {
      main: '#C40000',
    },
    warn: {
      main: '#FFBB00',
    },
    ok: {
      main: '#B1CE2E',
    },
    success: {
      main: '#008C13',
    },
    completed: {
      main: '#a61e72',
    },

    text: {
      primary: '#797a8e',
      secondary: '#555555',
    },
    pink: {
      light: '#cc1785',
      main: '#bb1d7f',
      dark: '#a61e72',
    },
    navy: {
      pale: '#797a8e',
      light: '#4c4d68',
      main: '#353755',
      dark: '#1f2142',
    },
    blue: {
      light: '#13a5dc',
      main: '#0089bd',
      dark: '#0d78a6',
    },
    teal: {
      light: '#16babf',
      main: '#0aa3a8',
      dark: '#0a969b',
    },
    darkgrey: {
      main: '#374b59',
    },
    yellow: {
      main: '#E8B021',
    },
    grey: {
      100: '#f9f9f9',
      200: '#f3f4f4',
      300: '#f0f0f0',
      400: '#cccccc',
      500: '#999999',
      600: '#676767',
    },
  },
  shadows: defaults.shadows,
  spacing: 4,
  shape: {
    borderRadius: 5,
  },
  transitions: {
    easing: {
      easeInOut: 'ease-in-out',
    },
  },
  tooltip: {
    backgroundColor: '#FFF',
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: defaults.shadows[1],
    fontSize: 13,
  },
  overrides: {
    MuiButton: {
      root: {
        padding: '8px 16px',
        borderRadius: '2px',
      },
      label: {
        fontWeight: 500,
      },
      contained: {
        '&$disabled': {
          color: '#FFF !important',
          backgroundColor: '#bbb',
        },
      },
      outlined: {
        '&$disabled': {
          color: '#bbb',
          borderColor: '#bbb !important',
        },
      },
      outlinedSecondary: {
        border: '2px solid #bb1d7f !important',
        '&:hover': {
          backgroundColor: '#bb1d7f',
          color: 'white',
        },
      },
    },
    MuiLink: {
      root: {
        color: '#A63288',
      },
    },
    MuiDrawer: {
      modal: {
        zIndex: `calc(${defaults.zIndex.modal} + 2) !important`,
      },
    },
    MuiTab: {
      root: {
        '&$disabled': {
          color: '#ccc !important',
          '& img': {
            backgroundColor: '#ccc',
          },
        },
        '&.Mui-selected': {
          color: '#1B4383',
        },
      },
      textColorInherit: {
        opacity: 1,
      },
    },
    MuiTableCell: {
      body: {
        fontSize: '0.9375rem',
      },
      paddingCheckbox: {
        '&, &:last-child': {
          padding: '0 12px',
        },
      },
    },
    MuiFormLabel: {
      root: {
        fontFamily: "'Libre-franklin', Arial, sans-serif",
        fontWeight: 'bold',
        fontSize: 18,
        '&$required': {
          width: '133%',
          '&:after': {
            fontFamily: "'Quicksand', Arial, sans-serif",
            fontSize: 17,
            fontWeight: 'normal',
            visibility: 'visible',
            content: "'Required'",
            fontStyle: 'italic',
            color: '#d31e0c',
            float: 'right',
          },
        },
      },
      asterisk: {
        display: 'none',
      },
    },
    MuiInput: {
      input: {
        color: '#1b4383',
        fontSize: 16,
        padding: '6px 0 8px',
        '&::placeholder': {
          color: '#afafaf',
        },
        '&::-ms-clear': {
          display: 'none !important',
        },
      },
      underline: {
        '&$focused': {
          '&:after': {
            borderColor: '#bb1d7f',
          },
        },
        '&:before': {
          borderBottom: '1px solid #bbb',
        },
      },
    },
    MuiInputAdornment: {
      positionStart: {
        '& svg': {
          fontSize: 20,
        },
      },
    },
    MuiSelect: {
      icon: {
        color: '#bb1d7f',
      },
    },
    MUIDataTable: {
      paper: {
        backgroundColor: 'transparent',
        boxShadow: 'none',
      },
      tableRoot: {
        borderCollapse: 'separate',
        borderSpacing: '0 13px',
      },
      caption: {
        display: 'none',
      },
    },
    MUIDataTableHead: {
      main: {
        display: 'none',
      },
    },
    MUIDataTableToolbar: {
      left: {
        display: 'none',
      },
      actions: {
        textAlign: 'left',
      },
    },
    MUIDataTableBodyRow: {
      hover: {
        backgroundColor: 'transparent !important',
      },
    },
    MUIDataTableBodyCell: {
      cellHide: {
        display: 'none !important',
      },
      responsiveStacked: {
        display: 'table-cell !important',
      },
      stackedCommon: {
        width: '100% !important',
      },
    },
    MUIDataTableBody: {
      emptyTitle: {
        fontSize: 16,
        wordWrap: 'break-word',
        whiteSpace: 'normal',
      },
    },
    MUIDataTablePagination: {
      root: {
        padding: '0 !important',
        fontSize: 16,
      },
      toolbar: {
        '& span': {
          fontSize: 16,
        },
      },
    },
    MuiTablePagination: {
      select: {
        paddingRight: 20,
      },
    },
    MuiTooltip: {
      tooltip: {
        fontSize: '14px',
        backgroundColor: '#444',
      },
    },
    MuiStepIcon: {
      root: {
        '&$completed': {
          color: '#39B05A',
        },
        '&$active': {
          color: '#bb1d7f',
        },
      },
      text: {
        fontSize: 16,
        transform: 'translateY(2px)',
      },
    },
    MuiStepConnector: {
      active: {
        '&>span': {
          borderColor: '#bb1d7f',
        },
      },
      completed: {
        '&>span': {
          borderColor: '#bb1d7f',
        },
      },
      root: {
        '&$disabled': {
          '&>span': {
            borderColor: '#6f6f6f',
          },
        },
      },
    },
    MuiStepLabel: {
      active: {
        color: '#bb1d7f !important',
        '&:hover': {
          textDecoration: 'underline',
        },
      },
      completed: {
        '&:hover': {
          textDecoration: 'underline',
        },
      },
      root: {
        '&$disabled': {
          '& svg': {
            fill: 'transparent',
            border: '2px solid #6f6f6f',
            boxSizing: 'border-box',
            borderRadius: 24,
            '& text': {
              fill: '#6f6f6f',
            },
          },
        },
      },
      label: {
        fontFamily: "'Libre-franklin', Arial, sans-serif",
        fontWeight: 'bold !important',
        '&$alternativeLabel': {
          fontSize: 15,
          marginTop: 10,
          lineHeight: 1.2,
        },
      },
    },
    MuiTabs: {
      indicator: {
        height: 4,
        borderRadius: 4,
      },
    },
    MuiTableRow: {
      root: {
        height: 48,
        '&.Mui-selected': {
          backgroundColor: '#f5f5f5',
        },
      },
      hover: {
        '&:hover': {
          backgroundColor: '#f2f2f2 !important',
        },
      },
    },
    MuiModal: {
      root: {
        zIndex: 1301,
      },
    },
    MuiDialog: {
      root: {
        zIndex: '1301 !important',
      },
    },
    MuiPopover: {
      root: {
        zIndex: '1301 !important',
      },
    },
  },
});
export default PexaMuiTheme;
