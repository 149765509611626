const API_PATH = '/api/web/v1';
const PROFILE_API_PATH = '/profile/v1';
const SUMMARY_BFF_API_PATH = '/bff/v1';
const DIGICERTS_API_PATH = '/digicerts/v1';
const INVOICE_API_PATH = '/invoice/v1';
const NOTIFY_API_PATH = '/notify/v1';
let rootUrl = `${window.location.protocol}//${window.location.host}`;

const envs = {

  // LOCAL
  'http://localhost:3000': {
    APP_ENV: 'LOCAL',
    PROJECTS_URL: '/',
    HOST_URL: rootUrl,
    BY_PASS_AUTH: true,
    FEATURE_CLIENT_DETAIL: true,
    FEATURE_LOTPLAN: true,
    WORKSPACES_URL: '/',
    HELP_CENTRE_URL: '/',
    APP_DYNAMICS_EUM_ID: 'LOCAL_APP_ID',
    API_ENDPOINT: 'http://localhost:4000',
    PROFILE_API_ENDPOINT: 'http://localhost:4000',
    SUMMARY_BFF_API_ENDPOINT: 'http://localhost:4000',
    DIGICERTS_API_ENDPOINT: 'http://localhost:4000',
    INVOICE_API_ENDPOINT: 'http://localhost:4000',
    NOTIFY_API_ENDPOINT: 'http://localhost:4000',
  },

  // DEV
  'https://plus-dev.pexalabs.com.au': {
    APP_ENV: 'DEV',
    HOST_URL: rootUrl,
    API_ENDPOINT: rootUrl + API_PATH,
    PROFILE_API_ENDPOINT: rootUrl + PROFILE_API_PATH,
    SUMMARY_BFF_API_ENDPOINT: rootUrl + SUMMARY_BFF_API_PATH,
    DIGICERTS_API_ENDPOINT: rootUrl + DIGICERTS_API_PATH,
    INVOICE_API_ENDPOINT: rootUrl + INVOICE_API_PATH,
    NOTIFY_API_ENDPOINT: rootUrl + NOTIFY_API_PATH,
    APP_DYNAMICS_EUM_ID: 'SY-AAB-BNK',
    FEATURE_CLIENT_DETAIL: false,
    FEATURE_LOTPLAN: false,
    PROJECTS_URL: 'https://pddh1.pexa.io',
    WORKSPACES_URL: 'https://dh1-dev-workspaces.pexalabs.com.au/pexa_web',
    HELP_CENTRE_URL: 'https://pexa--uat.sandbox.my.site.com/helpcentre/s/',
  },

  // TST
  'https://plus-tst.pexalabs.com.au': {
    APP_ENV: 'TST',
    HOST_URL: rootUrl,
    API_ENDPOINT: rootUrl + API_PATH,
    PROFILE_API_ENDPOINT: rootUrl + PROFILE_API_PATH,
    SUMMARY_BFF_API_ENDPOINT: rootUrl + SUMMARY_BFF_API_PATH,
    DIGICERTS_API_ENDPOINT: rootUrl + DIGICERTS_API_PATH,
    INVOICE_API_ENDPOINT: rootUrl + INVOICE_API_PATH,
    NOTIFY_API_ENDPOINT: rootUrl + NOTIFY_API_PATH,
    APP_DYNAMICS_EUM_ID: 'SY-AAB-BNK',
    FEATURE_CLIENT_DETAIL: true,
    FEATURE_LOTPLAN: true,
    PROJECTS_URL: 'https://pdtst.pexa.io',
    WORKSPACES_URL: 'https://s02-tst-workspaces.pexalabs.com.au/pexa_web',
    HELP_CENTRE_URL: 'https://pexa--uat.sandbox.my.site.com/helpcentre/s/',
  },
  // PRF
  'https://plus-prf.pexalabs.com.au': {
    APP_ENV: 'PRF',
    HOST_URL: rootUrl,
    API_ENDPOINT: rootUrl + API_PATH,
    PROFILE_API_ENDPOINT: rootUrl + PROFILE_API_PATH,
    SUMMARY_BFF_API_ENDPOINT: rootUrl + SUMMARY_BFF_API_PATH,
    DIGICERTS_API_ENDPOINT: rootUrl + DIGICERTS_API_PATH,
    INVOICE_API_ENDPOINT: rootUrl + INVOICE_API_PATH,
    NOTIFY_API_ENDPOINT: rootUrl + NOTIFY_API_PATH,
    APP_DYNAMICS_EUM_ID: 'SY-AAB-BNK',
    FEATURE_CLIENT_DETAIL: false,
    FEATURE_LOTPLAN: false,
    PROJECTS_URL: 'https://pdpst.pexa.com.au',
    WORKSPACES_URL: 'https://prf02-workspaces.pexalabs.com.au/pexa_web',
    HELP_CENTRE_URL: 'https://pexa--uat.sandbox.my.site.com/helpcentre/s/',
  },
  // PST
  'https://plus-pst.pexalabs.com.au': {
    APP_ENV: 'PST',
    HOST_URL: rootUrl,
    API_ENDPOINT: rootUrl + API_PATH,
    PROFILE_API_ENDPOINT: rootUrl + PROFILE_API_PATH,
    SUMMARY_BFF_API_ENDPOINT: rootUrl + SUMMARY_BFF_API_PATH,
    DIGICERTS_API_ENDPOINT: rootUrl + DIGICERTS_API_PATH,
    INVOICE_API_ENDPOINT: rootUrl + INVOICE_API_PATH,
    NOTIFY_API_ENDPOINT: rootUrl + NOTIFY_API_PATH,
    APP_DYNAMICS_EUM_ID: 'SY-AAB-BNK',
    FEATURE_CLIENT_DETAIL: false,
    FEATURE_LOTPLAN: false,
    PROJECTS_URL: 'https://pdpst.pexa.com.au',
    WORKSPACES_URL: 'https://pst-workspaces.pexalabs.com.au/pexa_web',
    HELP_CENTRE_URL: 'https://pexa--uat.sandbox.my.site.com/helpcentre/s/',
  },

  // PRD
  'https://plus.pexa.com.au': {
    APP_ENV: 'PROD',
    HOST_URL: rootUrl,
    API_ENDPOINT: rootUrl + API_PATH,
    PROFILE_API_ENDPOINT: rootUrl + PROFILE_API_PATH,
    SUMMARY_BFF_API_ENDPOINT: rootUrl + SUMMARY_BFF_API_PATH,
    DIGICERTS_API_ENDPOINT: rootUrl + DIGICERTS_API_PATH,
    INVOICE_API_ENDPOINT: rootUrl + INVOICE_API_PATH,
    NOTIFY_API_ENDPOINT: rootUrl + NOTIFY_API_PATH,
    APP_DYNAMICS_EUM_ID: 'SY-AAB-CCB',
    FEATURE_CLIENT_DETAIL: false,
    FEATURE_LOTPLAN: false,
    PROJECTS_URL: 'https://projects.pexa.com.au',
    WORKSPACES_URL: 'https://workspaces.pexa.com.au/pexa_web',
    HELP_CENTRE_URL: 'https://pexa.com.au/help',
  },
};

if (rootUrl.startsWith('http://local')) { // Add support for local tests
  rootUrl = 'http://localhost:3000';
}

const envValues = envs[rootUrl] || envs['https://plus.pexa.com.au'];

export default envValues;
