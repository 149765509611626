export const GET_MYLEARNING = 'GET_MYLEARNING';
export const GET_MYLEARNING_SUCCESS = 'GET_MYLEARNING_SUCCESS';
export const GET_MYLEARNING_FAIL = 'GET_MYLEARNING_FAIL';

export const MARK_DOCUMENT_READ = 'MARK_DOCUMENT_READ';
export const MARK_DOCUMENT_SUCCESS = 'MARK_DOCUMENT_SUCCESS';
export const MARK_DOCUMENT_FAIL = 'MARK_DOCUMENT_FAIL';

export function getMyLearning() {
  return {
    types: [GET_MYLEARNING, GET_MYLEARNING_SUCCESS, GET_MYLEARNING_FAIL],
    payload: {
      request: {
        url: '/summary?my_learning',
      },
    },
  };
}

export function markDocumentAsRead(checklistItemId, value) {
  return {
    types: [MARK_DOCUMENT_READ, MARK_DOCUMENT_SUCCESS, MARK_DOCUMENT_FAIL],
    payload: {
      request: {
        method: 'PUT',
        url: `/mylearning/${checklistItemId}`,
        data: {
          itemCompletedIndicator: value,
        },
      },
    },
  };
}
