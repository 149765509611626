import {
  GET_PRETOTYPE,
  GET_PRETOTYPE_SUCCESS,
  GET_PRETOTYPE_FAIL,
  ACK_PRETOTYPE,
  ACK_PRETOTYPE_SUCCESS,
  ACK_PRETOTYPE_FAIL,
} from '../actions/pretotype';

export const initialState = {
  data: null,
  loading: false,
  error: null,
  ack_pretotype: { loading: false, error: null },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_PRETOTYPE:
      return {
        ...state,
        ...{
          data: null,
          error: null,
          loading: true,
        },
      };
    case GET_PRETOTYPE_SUCCESS:
      return {
        ...state,
        ...{
          data: action.payload,
          error: null,
          loading: false,
        },
      };
    case GET_PRETOTYPE_FAIL:
      return {
        ...state,
        ...{
          data: null,
          error: action.error,
          loading: false,
        },
      };
    case ACK_PRETOTYPE:
      return {
        ...state,
        ...{ ack_pretotype: { error: null, loading: true } },
      };
    case ACK_PRETOTYPE_SUCCESS:
      return {
        ...state,
        ...{ ack_pretotype: { error: null, loading: false } },
      };
    case ACK_PRETOTYPE_FAIL:
      return {
        ...state,
        ...{ ack_pretotype: { error: true, loading: false } },
      };
    default:
      return state;
  }
};
