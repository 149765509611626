import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Doughnut } from 'react-chartjs-2';

import withStyles from '@material-ui/core/styles/withStyles';
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';
import {
  AppBar, Avatar, Typography, ListItem, ListItemIcon,
  ListItemText, List, Table, TableRow, TableCell, Link, TableBody,
} from '@material-ui/core';

import Paper from '../paper';
import ToggledPopper from './toggled-popper';
import { getLogoutEndpoint, authenticated } from '../../app/security';
import { userInitials } from '../../utils/helper';

class Header extends React.Component {
  getOtherAppsAsRows = () => {
    const { otherApps } = this.props;
    const rows = [];
    const chunk = 3;

    for (let i = 0, j = otherApps.length; i < j; i += chunk) {
      rows.push(otherApps.slice(i, i + chunk));
    }

    return rows;
  };

  logout = () => {
    const appRout = window.location.pathname;
    window.location = getLogoutEndpoint(appRout);
  };

  render() {
    const {
      logoSrc, appName, logoHlp, hlpUrl, hlpAlt, displayName,
      partyName, subscriberRating, classes, width,
    } = this.props;

    const rating = subscriberRating.split(',')[1];

    if (authenticated() === null) {
      return null;
    }

    return (
      <Fragment>
        <AppBar classes={{ root: classes.appBarRoot }} className={classes.appBar} id="appbar">
          <div className={classes.logoAndAppsSections}>
            <img className={classes.brandLogo} src={logoSrc} alt={appName} />
            <div className={classes.appsSection}>
              <Link
                href={hlpUrl}
                target="_blank"
                className={classes.helpLink}
              >
                <img
                  src={logoHlp}
                  className={classes.helpLogo}
                  alt={hlpAlt}
                />
              </Link>
              <ToggledPopper
                id="apps-popper-toggle"
                iconFunc={checked => (
                  <svg
                    className={[classes.appsIcon, checked ? classes.appsIconChecked : '']
                      .join(' ')
                      .trim()}
                    x="0px"
                    y="0px"
                    viewBox="0 0 27.3 27.3"
                  >
                    <path d="M13.7,0C6.1,0,0,6.1,0,13.7c0,7.5,6.1,13.7,13.7,13.7c7.5,0,13.7-6.1,13.7-13.7C27.3,6.1,21.2,0,13.7,0z M10.5,20.1H7.3v-3.2h3.2V20.1z M10.5,15.3H7.3v-3.2h3.2V15.3z M10.5,10.5H7.3V7.3h3.2V10.5z M15.3,20.1h-3.2v-3.2h3.2V20.1z M15.3,15.3h-3.2v-3.2h3.2V15.3z M15.3,10.5h-3.2V7.3h3.2V10.5z M20.1,20.1h-3.2v-3.2h3.2V20.1z M20.1,15.3h-3.2v-3.2h3.2V15.3z M20.1,10.5h-3.2V7.3h3.2V10.5z" />
                  </svg>
                )}
                checkboxProps={{
                  className: classes.appCheckboxProps,
                }}
                popperProps={{
                  placement: 'bottom-end',
                  modifiers: {
                    preventOverflow: {
                      enabled: true,
                      boundariesElement: 'viewport',
                    },
                    offset: {
                      enabbled: true,
                      offset: '60, 20',
                    },
                    arrow: {
                      enabled: true,
                    },
                  },
                }}
              >
                <Paper className={classes.appDropdownPaper}>
                  <Table size="small">
                    <TableBody>
                      {this.getOtherAppsAsRows().map((row, index) => (
                        // eslint-disable-next-line react/no-array-index-key
                        <TableRow className={classes.appTableRow} key={`otherAppRow${index}`}>
                          {row.map(otherApp => (
                            <TableCell
                              className={classes.appTableCell}
                              classes={{
                                root: classes.appTableCellRoot,
                              }}
                              key={otherApp.appName}
                              id={`other-apps-${otherApp.appName.toLowerCase().replace(/ /g, '-')}`}
                            >
                              <Link
                                href={otherApp.appURL}
                                target="_blank"
                                className={classes.appLink}
                              >
                                <img
                                  src={otherApp.logoSrc}
                                  className={[classes.appsIcon, otherApp.current ? classes.appIconDisabled : '']
                                    .join(' ')
                                    .trim()}
                                  alt={otherApp.appName}
                                />
                                <Typography className={classes.otherAppName} variant="h6">{otherApp.appName}</Typography>
                              </Link>
                            </TableCell>
                          ))}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Paper>
              </ToggledPopper>
            </div>
          </div>
          <div id="userSection" className={classes.userSection}>
            <ToggledPopper
              iconFunc={() => (
                <Fragment>
                  <Avatar className={classes.userAvatar}>{userInitials(displayName)}</Avatar>
                  <Typography variant="h6" className={classes.userDetails}>
                    {displayName}
                    <br />
                    {partyName}
                  </Typography>
                </Fragment>
              )}
              checkboxProps={{
                className: classes.userCheckboxProps,
              }}
              popperProps={{
                placement: 'bottom-start',
                modifiers: {
                  preventOverflow: {
                    enabled: true,
                    boundariesElement: 'viewport',
                  },
                  offset: {
                    enabled: true,
                    offset: `${isWidthUp('md', width) ? -18 : -147} , 20`,
                  },
                  arrow: {
                    enabled: true,
                  },
                },
              }}
            >
              <Paper id="logout" className={classes.userDropdownPaper}>
                <List className={classes.userDropdownList}>
                  {subscriberRating?.indexOf(',') !== -1 && (
                    <ListItem alignItems="flex-start" divider disableGutters>
                      <div className={classes.ratingDonut}>
                        <div className={classes.doughnutHole}>
                          <Typography className={classes.rating}>
                            {rating}
                          </Typography>
                        </div>
                        <Doughnut
                          data={{ datasets: [{ data: [rating, 10 - rating], backgroundColor: ['#90297d'] }] }}
                          options={{
                            responsiveAnimationDuration: 0,
                            legend: { display: false },
                            responsive: true,
                            maintainAspectRatio: false,
                            cutoutPercentage: 80,
                            tooltips: { enabled: false },
                            elements: {
                              arc: {
                                borderWidth: 0,
                              },
                            },
                          }}
                        />
                      </div>
                      <div className={classes.peerRating} id="subscriber-rating">
                        <Typography gutterBottom>Peer Rating</Typography>
                        <Typography>{`Based on ${subscriberRating.split(',')[0]} ratings`}</Typography>
                        <Link target="_blank" href="https://www.pexa.com.au/peer-ratings">
                          Learn more
                        </Link>
                      </div>
                    </ListItem>
                  )}
                  <ListItem button component="a" onClick={this.logout}>
                    <ListItemIcon>
                      <svg
                        className={classes.userDropdownIcon}
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                        x="0px"
                        y="0px"
                        viewBox="0 0 24 24"
                      >
                        <path d="M16.6 5.4L15 7a6 6 0 0 1 3 5 6 6 0 0 1-6 6 6 6 0 0 1-6-6 6 6 0 0 1 2.9-5.1L7.4 5.4A8 8 0 0 0 4 12a8 8 0 0 0 8 8 8 8 0 0 0 8-8 8 8 0 0 0-3.4-6.6M13 3h-2v10h2" />
                      </svg>
                    </ListItemIcon>
                    <ListItemText
                      classes={{
                        root: classes.userDropdownText,
                        primary: classes.userDropdownTextPrimary,
                      }}
                    >
                      Log out
                    </ListItemText>
                  </ListItem>
                </List>
              </Paper>
            </ToggledPopper>
          </div>
        </AppBar>
      </Fragment>
    );
  }
}

Header.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string),
  width: PropTypes.string.isRequired,
  logoSrc: PropTypes.string.isRequired,
  appName: PropTypes.string.isRequired,
  logoHlp: PropTypes.string.isRequired,
  hlpUrl: PropTypes.string.isRequired,
  hlpAlt: PropTypes.string.isRequired,
  otherApps: PropTypes.arrayOf(
    PropTypes.shape({
      appName: PropTypes.string.isRequired,
      logoSrc: PropTypes.string.isRequired,
      appURL: PropTypes.string.isRequired,
    }),
  ),
  displayName: PropTypes.string.isRequired,
  partyName: PropTypes.string.isRequired,
  subscriberRating: PropTypes.string,
};

Header.defaultProps = {
  classes: {},
  otherApps: [],
  subscriberRating: '',
};

const style = theme => ({
  appBar: {
    boxShadow: theme.shadows[1],
  },
  appBarRoot: {
    backgroundColor: theme.palette.blue.main,
    height: 67,
    display: 'flex',
    flexDirection: 'row',
    zIndex: theme.zIndex.modal + 1, // so app/user poppers will appear over modal in mobile nav
  },
  logoAndAppsSections: {
    paddingLeft: 18,
    backgroundColor: theme.palette.common.white,
    display: 'flex',
    flex: 1,
  },
  brandLogo: {
    width: 150,
    height: 65,
  },
  helpLink: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 9,
    marginRight: -2,
  },
  helpLogo: {
    width: 25,
    height: 25,
  },
  appsSection: {
    flex: 1,
    display: 'flex',
    justifyContent: 'flex-end',
  },
  appsIcon: {
    width: 35,
    height: 35,
    '& > path': {
      fill: theme.palette.grey['400'],
    },
  },
  appsIconChecked: {
    color: 'initial !important',
    '& path': {
      fill: theme.palette.blue.main,
    },
  },
  appIconDisabled: {
    opacity: 0.2,
  },
  appCheckboxProps: {
    color: 'initial !important',
    marginRight: -12,
    '&:hover svg > path': {
      transition: `0.3s fill ${theme.transitions.easing.easeInOut}`,
      fill: theme.palette.blue.main,
    },
  },
  appDropdownPaper: {
    padding: theme.spacing(2),
    width: 310,
    transition: `0.3s all ${theme.transitions.easing.easeInOut}`,

    '&::before': {
      content: "''",
      position: 'absolute',
      borderStyle: 'solid',
      borderWidth: '0 11px 11px',
      borderColor: `${theme.palette.common.white} transparent`,
      display: 'block',
      width: '0',
      top: '-11px',
      right: 78,
    },
  },
  appTableRow: {
    verticalAlign: 'top',
  },
  appTableCell: {
    display: 'inline-flex',
    justifyContent: 'center',
    width: '33%',
    borderBottom: 'none',
  },
  appTableCellRoot: {
    padding: 16,
  },
  appLink: {
    textDecoration: 'none !important',
    textAlign: 'center',
    minWidth: 64,
  },
  otherAppName: {
    paddingTop: theme.spacing(2),
  },
  userSection: {
    display: 'flex',
    textAlign: 'left',
    minWidth: 210,
    padding: '0 18px',
    backgroundColor: theme.palette.common.white,
    transition: `0.3s all ${theme.transitions.easing.easeInOut}`,
    '&:hover': {
      backgroundColor: theme.palette.common.white,
      '& h6': {
        textDecoration: 'underline',
      },
    },
    [theme.breakpoints.down('sm')]: {
      minWidth: 60,
    },
  },
  userCheckboxProps: {
    color: 'transparent !important',
    padding: 0,
    textAlign: 'left',
    '&:hover': {
      backgroundColor: 'unset',
    },
  },
  userAvatar: {
    width: 35,
    height: 35,
    ...theme.typography.h4,
    color: theme.palette.common.white,
    backgroundColor: '#90297d',
  },
  userDetails: {
    color: theme.palette.text.primary,
    padding: 0,
    margin: '0 0 0 9px',
    maxWidth: 150,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    zIndex: '',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  userDropdownPaper: {
    backgroundColor: theme.palette.common.white,
    transition: `0.3s all ${theme.transitions.easing.easeInOut}`,

    '&:hover': {
      backgroundColor: theme.palette.grey['300'],
      '&::before': {
        transition: 'inherit',
        borderColor: `${theme.palette.grey['300']} transparent`,
      },
    },
    width: 185,
    padding: 0,

    '&::before': {
      content: "''",
      position: 'absolute',
      borderStyle: 'solid',
      borderWidth: '0 11px 11px',
      borderColor: `${theme.palette.common.white} transparent`,
      display: 'block',
      width: '0',
      top: '-11px',
      left: '70%',
      [theme.breakpoints.down('sm')]: {
        left: '80%',
      },
    },
  },
  userDropdownList: {
    padding: '0px 15px 0 15px',
  },
  userDropdownIcon: {
    height: 20,
    '& path': {
      fill: theme.palette.text.primary,
    },
  },
  userDropdownText: {
    paddingLeft: 0,
  },
  userDropdownTextPrimary: {
    ...theme.typography.h5,
  },
  ratingDonut: {
    width: 65,
    height: 65,
    margin: '10px 5px 5px 5px',
    position: 'relative',
  },
  doughnutHole: {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    width: '100%',
    textAlign: 'center',
  },
  rating: {
    fontSize: '24px !important',
    fontWeight: 'bold',
    lineHeight: 1.1,
  },
  peerRating: {
    margin: '10px 0 10px 10px',
    lineHeight: 0.5,
    '& p,a': {
      fontSize: 10,
    },
    '& a': {
      textDecoration: 'underline',
    },
    '& > p:first-child': {
      fontWeight: 'bold',
      fontSize: 12,
    },
  },
});

export default withStyles(style, { withTheme: true })(withWidth()(Header));
