/* istanbul ignore file */
import EnvValues from '../conf/config';
import { getUserInfo } from '../app/security';

window['adrum-start-time'] = new Date().getTime();

const userInfo = getUserInfo();

/* eslint no-param-reassign: "error" */
((config) => {
  config.appKey = EnvValues.APP_DYNAMICS_EUM_ID;
  config.adrumExtUrlHttp = './assets/app-dynamics';
  config.adrumExtUrlHttps = './assets/app-dynamics';
  config.beaconUrlHttp = EnvValues.HOST_URL;
  config.beaconUrlHttps = EnvValues.HOST_URL;
  config.xd = { enable: false };
  config.spa = {
    spa2: true,
  };
})(window['adrum-config'] || (window['adrum-config'] = {
  userEventInfo: {
    PageView() {
      return {
        userData: {
          userName: userInfo.username,
          fullName: userInfo.fullName,
          subscriberName: userInfo.partyName,
        },
      };
    },
    Ajax() {
      return {
        userData: {
          userName: userInfo.username,
          fullName: userInfo.fullName,
          subscriberName: userInfo.partyName,
        },
      };
    },
    VPageView() {
      return {
        userData: {
          userName: userInfo.username,
          fullName: userInfo.fullName,
          subscriberName: userInfo.partyName,
        },
      };
    },
  },
}));

document.write(`${unescape('%3Cscript')} src='./assets/app-dynamics/adrum-latest.js' type='text/javascript' charset='UTF-8'${unescape('%3E%3C/script%3E')}`);
