export const GET_SUMMARY = 'GET_SUMMARY';
export const GET_SUMMARY_SUCCESS = 'GET_SUMMARY_SUCCESS';
export const GET_SUMMARY_FAIL = 'GET_SUMMARY_FAIL';

export function getSummary() {
  return {
    types: [GET_SUMMARY, GET_SUMMARY_SUCCESS, GET_SUMMARY_FAIL],
    payload: {
      client: 'bffClient',
      request: {
        url: '/summary',
      },
    },
  };
}
