export const toFixed = (num, precision = 2) => (+(`${Math.round(+(`${num}e${precision}`))}e${-precision}`)).toFixed(precision);

export const capitalizeFirstLetter = string => string.charAt(0).toUpperCase() + string.slice(1);

export const userInitials = (name = '') => {
  const nameSplit = String(name)
    .toUpperCase()
    .split(' ');

  let initials;
  if (nameSplit.length === 1) {
    initials = nameSplit[0] ? nameSplit[0].charAt(0) : '?';
  } else {
    initials = nameSplit[0].charAt(0) + nameSplit[1].charAt(0);
  }
  return initials;
};

export default toFixed;
