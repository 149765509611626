import { createStore, applyMiddleware } from 'redux';
import { createBrowserHistory } from 'history';
import axios from 'axios';
import { multiClientMiddleware } from 'redux-axios-middleware';
import { createReduxHistoryContext } from 'redux-first-history';

import reducers from './reducers';
import EnvValues from './conf/config';
import { getWebProfileEndpoint, removeCookie } from './app/security';

const { createReduxHistory, routerMiddleware, routerReducer } = createReduxHistoryContext({
  history: createBrowserHistory(),
});

const options = {
  interceptors: {
    response: [
      {
        // args are { getState, dispatch, getSourceAction }, response
        success: (_, response) => response.data,
        // args are { getState, dispatch, getSourceAction }, error
        error: (_, error) => {
          if (error.message === 'Network Error') {
            removeCookie();
            const appRoute = window.location ? window.location.pathname : '';
            window.location = getWebProfileEndpoint(appRoute);
          } else {
            throw error;
          }
        },
      },
    ],
  },
};

const clients = {
  default: {
    client: axios.create({
      responseType: 'json',
      headers: { Pragma: 'no-cache' },
      baseURL: EnvValues.API_ENDPOINT,
    }),
  },
  profileClient: {
    client: axios.create({
      responseType: 'json',
      headers: { Pragma: 'no-cache' },
      baseURL: EnvValues.PROFILE_API_ENDPOINT,
    }),
  },
  bffClient: {
    client: axios.create({
      responseType: 'json',
      headers: { Pragma: 'no-cache' },
      baseURL: EnvValues.SUMMARY_BFF_API_ENDPOINT,
    }),
  },
  digicertsClient: {
    client: axios.create({
      responseType: 'json',
      headers: { Pragma: 'no-cache' },
      baseURL: EnvValues.DIGICERTS_API_ENDPOINT,
    }),
  },
  invoiceClient: {
    client: axios.create({
      responseType: 'json',
      headers: { Pragma: 'no-cache' },
      baseURL: EnvValues.INVOICE_API_ENDPOINT,
    }),
  },
  notifyClient: {
    client: axios.create({
      responseType: 'json',
      headers: { Pragma: 'no-cache' },
      baseURL: EnvValues.NOTIFY_API_ENDPOINT,
    }),
  },
};

const store = createStore(
  reducers(routerReducer),
  {},
  applyMiddleware(
    routerMiddleware,
    multiClientMiddleware(clients, options),
  ),
);

export default store;
export const history = createReduxHistory(store);
