import React from 'react';
import PropTypes from 'prop-types';

import withStyles from '@material-ui/core/styles/withStyles';
import Info from '@material-ui/icons/Info';
import Error from '@material-ui/icons/Error';
import CheckCircle from '@material-ui/icons/CheckCircle';
import Cancel from '@material-ui/icons/Cancel';

export const IconTypes = {
  Info: 0,
  Warn: 1,
  Done: 2,
  Cancel: 3,
};

export const IconSizes = {
  Small: 0,
  Large: 1,
  XLarge: 2,
};

function getSizeClassName(classes, size) {
  switch (size) {
    case IconSizes.Small:
      return classes.smallIcon;
    case IconSizes.Large:
      return classes.largeIcon;
    case IconSizes.XLarge:
      return classes.xlargeIcon;
    default:
      return classes.smallIcon;
  }
}

function getIcon(classes, type, size) {
  const iconSizeClassName = getSizeClassName(classes, size);
  switch (type) {
    case IconTypes.Info:
      return <Info className={`${classes.infoIconStyle} ${iconSizeClassName}`} />;
    case IconTypes.Warn:
      return <Error className={`${classes.warnIconStyle} ${iconSizeClassName}`} />;
    case IconTypes.Done:
      return <CheckCircle className={`${classes.doneIconStyle} ${iconSizeClassName}`} />;
    case IconTypes.Cancel:
      return <Cancel className={`${classes.cancelIconStyle} ${iconSizeClassName}`} />;
    default:
      return '';
  }
}

const RoundIcon = ({ classes, iconType, iconSize }) => (
  getIcon(classes, iconType, iconSize)
);

const style = theme => ({
  infoIconStyle: {
    color: theme.palette.teal.light,
  },
  warnIconStyle: {
    color: theme.palette.warn.main,
  },
  doneIconStyle: {
    color: theme.palette.success.main,
  },
  cancelIconStyle: {
    color: theme.palette.error.main,
  },
  smallIcon: {
    fontSize: 22,
  },
  largeIcon: {
    fontSize: 35,
  },
  xlargeIcon: {
    fontSize: 45,
  },
});

RoundIcon.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  iconType: PropTypes.number,
  iconSize: PropTypes.number,
};

export default withStyles(style, { withTheme: true })(RoundIcon);
