import { GET_SUMMARY, GET_SUMMARY_FAIL, GET_SUMMARY_SUCCESS } from '../actions/summary';

import {
  PUT_MY_SUMMARY, PUT_MY_SUMMARY_FAIL, PUT_MY_SUMMARY_SUCCESS, RESET_MY_SUMMARY_STATE,
} from '../actions/mysummary';

import { GET_MYLEARNING, GET_MYLEARNING_FAIL, GET_MYLEARNING_SUCCESS } from '../actions/my-learning';

import { GET_INVITATIONS, GET_INVITATIONS_FAIL, GET_INVITATIONS_SUCCESS } from '../actions/invitations';

import { GET_ANNOUNCEMENTS, GET_ANNOUNCEMENTS_FAIL, GET_ANNOUNCEMENTS_SUCCESS } from '../actions/announcements';

import { GET_MY_WORKSPACES, GET_MY_WORKSPACES_FAIL, GET_MY_WORKSPACES_SUCCESS } from '../actions/my-workspaces';

import { GET_SETTLEMENTS, GET_SETTLEMENTS_FAIL, GET_SETTLEMENTS_SUCCESS } from '../actions/settlement-calendar';

import { GET_SETTLEMENTS_TODAY, GET_SETTLEMENTS_TODAY_FAIL, GET_SETTLEMENTS_TODAY_SUCCESS } from '../actions/settlements-today';

export const initialState = {
  data: null,
  error: null,
  loading: false,
  mySummary: {
    error: false,
    success: false,
    loading: false,
  },
  userHasExpiredCertificates: null,
  invitations: { loading: false, error: null },
  my_learning: { loading: false, error: null },
  my_workspaces: { loading: false, error: null },
  disbursements: { loading: false, error: null },
  announcements: { loading: false, error: null },
  todays_settlements: { loading: false, error: null },
  settlement_calendar: { loading: false, error: null },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_SUMMARY:
      return {
        ...state,
        ...{
          data: null,
          error: null,
          loading: true,
        },
      };

    case GET_SUMMARY_SUCCESS:
      return {
        ...state,
        ...{
          data: action.payload,
          error: null,
          loading: false,
        },
      };

    case GET_SUMMARY_FAIL:
      return {
        ...state,
        ...{
          data: null,
          error: action.error,
          loading: false,
        },
      };

    case GET_ANNOUNCEMENTS:
      return { ...state, ...{ announcements: { error: null, loading: true } } };

    case GET_ANNOUNCEMENTS_SUCCESS:
      return {
        ...state,
        ...{ data: { ...(state && state.data), ...{ announcementsWidget: action.payload } } },
        ...{ announcements: { error: null, loading: false } },
      };

    case GET_ANNOUNCEMENTS_FAIL:
      return { ...state, ...{ announcements: { error: action.error, loading: false } } };

    case GET_MYLEARNING:
      return { ...state, ...{ my_learning: { error: null, loading: true } } };

    case GET_MYLEARNING_SUCCESS:
      return {
        ...state,
        ...{ data: { ...(state && state.data), ...{ myLearningWidget: action.payload } } },
        ...{ my_learning: { error: null, loading: false } },
      };

    case GET_MYLEARNING_FAIL:
      return { ...state, ...{ my_learning: { error: action.error, loading: false } } };

    case GET_SETTLEMENTS_TODAY:
      return { ...state, ...{ todays_settlements: { error: null, loading: true } } };

    case GET_SETTLEMENTS_TODAY_SUCCESS:
      return {
        ...state,
        ...{
          data: {
            ...(state && state.data),
            ...{
              workspaceDataWidgets: {
                ...(state && state.data && state.data.workspaceDataWidgets),
                ...{ settlementsTodayWidget: action.payload },
              },
            },
          },
        },
        ...{ todays_settlements: { error: null, loading: false } },
      };

    case GET_SETTLEMENTS_TODAY_FAIL:
      return { ...state, ...{ todays_settlements: { error: action.error, loading: false } } };

    case GET_SETTLEMENTS:
      return { ...state, ...{ settlement_calendar: { error: null, loading: true } } };

    case GET_SETTLEMENTS_SUCCESS:
      return {
        ...state,
        ...{
          data: {
            ...(state && state.data),
            ...{
              workspaceDataWidgets: {
                ...(state && state.data && state.data.workspaceDataWidgets),
                ...{ settlementsCalendarWidget: action.payload },
              },
            },
          },
        },
        ...{ settlement_calendar: { error: null, loading: false } },
      };

    case GET_SETTLEMENTS_FAIL:
      return { ...state, ...{ settlement_calendar: { error: action.error, loading: false } } };

    case GET_MY_WORKSPACES:
      return { ...state, ...{ my_workspaces: { error: null, loading: true } } };

    case GET_MY_WORKSPACES_SUCCESS:
      return {
        ...state,
        ...{
          data: {
            ...(state && state.data),
            ...{
              workspaceDataWidgets: {
                ...(state && state.data && state.data.workspaceDataWidgets),
                ...{ myWorkspacesWidget: action.payload },
              },
            },
          },
        },
        ...{ my_workspaces: { error: null, loading: false } },
      };

    case GET_MY_WORKSPACES_FAIL:
      return { ...state, ...{ my_workspaces: { error: action.error, loading: false } } };

    case GET_INVITATIONS:
      return { ...state, ...{ invitations: { error: null, loading: true } } };

    case GET_INVITATIONS_SUCCESS:
      return {
        ...state,
        ...{
          data: {
            ...(state && state.data),
            ...{
              workspaceDataWidgets: {
                ...(state && state.data && state.data.workspaceDataWidgets),
                ...{ invitationsWidget: action.payload },
              },
            },
          },
        },
        ...{ invitations: { error: null, loading: false } },
      };

    case GET_INVITATIONS_FAIL:
      return { ...state, ...{ invitations: { error: action.error, loading: false } } };

    case RESET_MY_SUMMARY_STATE:
      return {
        ...state,
        ...{
          data: {
            ...(state && state.data),
            ...{
              mySummaryDataWidget: {
                ...{ widgetData: action.payload },
              },
            },
          },
        },
        ...{
          mySummary: {
            error: false,
            loading: false,
            success: false,
          },
        },
      };

    case PUT_MY_SUMMARY:
      return {
        ...state,
        ...{
          mySummary: {
            error: false,
            loading: true,
            success: false,
          },
        },
      };

    case PUT_MY_SUMMARY_SUCCESS:
      return {
        ...state,
        ...{
          mySummary: {
            error: false,
            success: true,
            loading: false,
          },
        },
      };

    case PUT_MY_SUMMARY_FAIL:
      return {
        ...state,
        ...{
          mySummary: {
            error: true,
            loading: false,
            success: false,
          },
        },
      };

    default:
      return state;
  }
};
