import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import { authenticated, getWebProfileEndpoint } from './security';

const ProtectedRoute = ({ children, hasAccess }) => {
  const location = useLocation();
  if (authenticated()) {
    if (hasAccess !== undefined && hasAccess === false) {
      return <Navigate to="/forbidden" />;
    }
    return children;
  }
  window.location = getWebProfileEndpoint(location.pathname);
  return null;
};

export default ProtectedRoute;
