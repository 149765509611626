import { combineReducers } from 'redux';

import summary from './summary';
import digicerts from './digicerts';
import notifications from './notifications';
import pretotype from './pretotype';
import accountManagement from './account-management';
import pexaKey from './pexa-key';
import errorPage from './errorPage';

export default routerReducer => combineReducers({
  router: routerReducer,
  summary,
  digicerts,
  notifications,
  pretotype,
  accountManagement,
  pexaKey,
  errorPage,
});
