export const GET_NOTIFICATIONS = 'GET_NOTIFICATIONS';
export const GET_NOTIFICATIONS_SUCCESS = 'GET_NOTIFICATIONS_SUCCESS';
export const GET_NOTIFICATIONS_FAIL = 'GET_NOTIFICATIONS_FAIL';
export const ACK_NOTIFICATION = 'ACK_NOTIFICATION';
export const ACK_NOTIFICATION_SUCCESS = 'ACK_NOTIFICATION_SUCCESS';
export const ACK_NOTIFICATION_FAIL = 'ACK_NOTIFICATION_FAIL';

export function getNotifications(type, jurisdictions) {
  return {
    types: [
      GET_NOTIFICATIONS,
      GET_NOTIFICATIONS_SUCCESS,
      GET_NOTIFICATIONS_FAIL,
    ],
    payload: {
      client: 'notifyClient',
      request: {
        url: `/notifications/${type}?jurisdictions=${jurisdictions}`,
      },
    },
  };
}

export function ackNotification(id) {
  return {
    types: [
      ACK_NOTIFICATION,
      ACK_NOTIFICATION_SUCCESS,
      ACK_NOTIFICATION_FAIL,
    ],
    payload: {
      client: 'notifyClient',
      request: {
        method: 'post',
        url: '/notifications/ack',
        data: { id },
      },
    },
  };
}
