/* istanbul ignore file */
import React from 'react';
import { useTheme, useMediaQuery } from '@material-ui/core';
import { useNavigate, useLocation, useParams } from 'react-router-dom';

export const withMediaQuery = (queries = []) => Component => (props) => {
  const mediaProps = {};
  queries.forEach((q) => {
    mediaProps[q[0]] = useMediaQuery(q[1]);
  });
  return <Component {...mediaProps} {...props} />;
};

export const withWidth = Component => (props) => {
  const theme = useTheme();
  const keys = [...theme.breakpoints.keys].reverse();
  const width = (
    keys.reduce((output, key) => {
      const matches = useMediaQuery(theme.breakpoints.up(key));
      return !output && matches ? key : output;
    }, null) || 'xs'
  );
  return <Component width={width} {...props} />;
};

export const withRouter = Component => (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  return <Component navigate={navigate} location={location} params={params} {...props} />;
};
