import {
  RETRIEVE_INVOICE,
  RETRIEVE_INVOICE_SUCCESS,
  RETRIEVE_INVOICE_FAIL,
  DOWNLOAD_INVOICE,
  DOWNLOAD_INVOICE_SUCCESS,
  DOWNLOAD_INVOICE_FAIL,
  PREPARE_INVOICE,
  PREPARE_INVOICE_SUCCESS,
  PREPARE_INVOICE_FAIL,
  GET_INVOICE_DOWNLOAD_LINK,
  GET_INVOICE_DOWNLOAD_LINK_SUCCESS,
  GET_INVOICE_DOWNLOAD_LINK_FAIL,
  GET_INVOICE_DOWNLOAD_STATUS,
  GET_INVOICE_DOWNLOAD_STATUS_SUCCESS,
  GET_INVOICE_DOWNLOAD_STATUS_FAIL,
  DOWNLOAD_INVOICE_DOCHUB,
  DOWNLOAD_INVOICE_DOCHUB_SUCCESS,
  DOWNLOAD_INVOICE_DOCHUB_FAIL,
} from '../actions/account-management';

export const initialState = {
  data: null,
  loading: false,
  error: null,
  retrieve_invoice: { loading: false, error: null },
  download_invoice: { loading: false, error: null },
  prepare_invoice: { loading: false, error: null },
  get_invoice_download_link: { loading: false, error: null },
  get_invoice_download_status: { loading: false, error: null },
  download_invoice_dochub: { loading: false, error: null },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case RETRIEVE_INVOICE:
      return {
        ...state,
        ...{ retrieve_invoice: { error: null, loading: true } },
      };
    case RETRIEVE_INVOICE_SUCCESS:
      return {
        ...state,
        ...{
          data: {
            ...(state && state.data),
            ...{ invoices: action.payload },
          },
          ...{ retrieve_invoice: { error: null, loading: false } },
        },
      };
    case RETRIEVE_INVOICE_FAIL:
      return {
        ...state,
        ...{ retrieve_invoice: { error: action.error, loading: false } },
      };
    case DOWNLOAD_INVOICE:
      return {
        ...state,
        ...{ download_invoice: { error: null, loading: true } },
      };
    case DOWNLOAD_INVOICE_SUCCESS:
      return {
        ...state,
        ...{ download_invoice: { error: false, loading: false } },
      };
    case DOWNLOAD_INVOICE_FAIL:
      return {
        ...state,
        ...{ download_invoice: { error: true, loading: false } },
      };
    case PREPARE_INVOICE:
      return {
        ...state,
        ...{ prepare_invoice: { error: null, loading: true } },
      };
    case PREPARE_INVOICE_SUCCESS:
      return {
        ...state,
        ...{ prepare_invoice: { error: null, loading: false } },
      };
    case PREPARE_INVOICE_FAIL:
      return {
        ...state,
        ...{ prepare_invoice: { error: true, loading: false } },
      };
    case GET_INVOICE_DOWNLOAD_LINK:
      return {
        ...state,
        ...{ get_invoice_download_link: { error: null, loading: true } },
      };
    case GET_INVOICE_DOWNLOAD_LINK_SUCCESS:
      return {
        ...state,
        ...{ get_invoice_download_link: { error: null, loading: false } },
      };
    case GET_INVOICE_DOWNLOAD_LINK_FAIL:
      return {
        ...state,
        ...{ get_invoice_download_link: { error: true, loading: false } },
      };
    case GET_INVOICE_DOWNLOAD_STATUS:
      return {
        ...state,
        ...{ get_invoice_download_status: { error: null, loading: true } },
      };
    case GET_INVOICE_DOWNLOAD_STATUS_SUCCESS:
      return {
        ...state,
        ...{ get_invoice_download_status: { error: null, loading: false } },
      };
    case GET_INVOICE_DOWNLOAD_STATUS_FAIL:
      return {
        ...state,
        ...{ get_invoice_download_status: { error: true, loading: false } },
      };
    case DOWNLOAD_INVOICE_DOCHUB:
      return {
        ...state,
        ...{ download_invoice_dochub: { error: null, loading: true } },
      };
    case DOWNLOAD_INVOICE_DOCHUB_SUCCESS:
      return {
        ...state,
        ...{ download_invoice_dochub: { error: false, loading: false } },
      };
    case DOWNLOAD_INVOICE_DOCHUB_FAIL:
      return {
        ...state,
        ...{ download_invoice_dochub: { error: true, loading: false } },
      };
    default:
      return state;
  }
};
