import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import withStyles from '@material-ui/core/styles/withStyles';
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Popper from '@material-ui/core/Popper';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Slide from '@material-ui/core/Slide';
import Fade from '@material-ui/core/Fade';
import Collapse from '@material-ui/core/Collapse';
import Checkbox from '@material-ui/core/Checkbox';
import ExpandIcon from '@material-ui/icons/Add';
import CollapseIcon from '@material-ui/icons/Remove';

import AnimatedBurgerButton from './animated-burger-button';
import RoundIcon, { IconTypes, IconSizes } from '../round-icon';
import { authenticated } from '../../app/security';
import { withRouter } from '../../utils/hookWrapper';

export const TOOLBAR_NAV_TOP = 67;
const DRAWER_WIDTH = 115;
const DRAWER_TRANSITION_MS = 450;
const TOOLBAR_HEIGHT = 50;

class Nav extends React.Component {
  state = {
    mobileOpen: false,
    activeListItems: [null, null],
    activeMenuOptions: [null, null],
  };

  componentDidUpdate(prevProps) {
    const { width: prevWidth } = prevProps;
    const { width } = this.props;

    // Close mobile drawer if we have resized (up) to remove it.
    if (width === 'lg' && (prevWidth === 'xs' || prevWidth === 'sm' || prevWidth === 'md')) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ mobileOpen: false });
    }
  }

  handleDrawerToggle = () => {
    this.setState((state) => {
      if (!state.mobileOpen) {
        return { mobileOpen: true };
      }

      this.handleClickAway();
      return {};
    });
  };

  isSelected = (optionPath, depth) => {
    const { location } = this.props;
    if (depth === 0) {
      return location.pathname.substring(0, optionPath.length) === optionPath;
    }
    return location.pathname === optionPath;
  };

  handleListItemClick = (menuOption, depth, e) => {
    const { navigate } = this.props;
    const { activeMenuOptions } = this.state;

    if ('menuItems' in menuOption) {
      this.expandOrCollapseSubMenu(e, menuOption, depth, activeMenuOptions[depth] !== menuOption);
    } else if (menuOption.linkTo) {
      navigate(menuOption.linkTo);
      this.handleClickAway();
    }
  };

  expandOrCollapseSubMenu = (e, menuOption, depth, expand) => {
    const { activeListItems, activeMenuOptions } = this.state;
    const { currentTarget } = e;

    activeMenuOptions[depth] = expand ? menuOption : null;
    activeListItems[depth] = expand ? currentTarget : null;

    this.setState({
      activeListItems,
      activeMenuOptions,
    });
  };

  handleClickAway = () => {
    this.setState({
      mobileOpen: false,
      activeListItems: [null, null],
      activeMenuOptions: [null, null],
    });
  };

  getListItemForMenuOption = (menuOption, depth, index) => {
    const { mobileOpen, activeMenuOptions } = this.state;
    const { classes, width } = this.props;

    const isDesktopNav = isWidthUp('lg', width);
    const menuOptionActive = activeMenuOptions[depth] === menuOption;
    const horizontalItemLayout = !isDesktopNav || (isDesktopNav && depth !== 0);

    // If it's lg up expand on mouseover
    const listItemAttributesLg = isDesktopNav
      ? {
        onFocus: e => this.expandOrCollapseSubMenu(e, menuOption, depth, true),
        onMouseEnter: e => this.expandOrCollapseSubMenu(e, menuOption, depth, true),
      }
      : {};

    const listItem = (
      <ListItem
        id={`nav-menu-item-item-${index}-depth-${depth}`}
        className={[
          classes.listItem,
          this.isSelected(menuOption.linkTo, depth)
            && horizontalItemLayout && classes.listItemSelectedHorizontal,
          this.isSelected(menuOption.linkTo, depth)
            && !horizontalItemLayout && classes.listItemSelected,

          // mobile submenu item ... base style, and indented style
          ...(!isDesktopNav && depth !== 0
            ? [classes.listItemMobileNonRoot, classes[`listItemMobileNonRootDepth${depth}`]]
            : []),

          // mobile root item with submenu
          !isDesktopNav && depth === 0 && menuOption.menuItems && classes.listItemMobileHasSub,

          // non root desktop item or mobile
          horizontalItemLayout && classes.listItemHorizontal,

          // transition delay for individual menu item slide
          (isDesktopNav && depth !== 0) || (!isDesktopNav && depth === 0)
            ? classes[`listItemIndex${index}`]
            : '',
        ]
          .join(' ')
          .trim()}
        onClick={e => this.handleListItemClick(menuOption, depth, e)}
        button
        {...listItemAttributesLg}
      >
        <ListItemIcon
          className={
            !horizontalItemLayout ? classes.listItemIcon : classes.listItemIconSmall
          }
        >
          <div
            id={menuOption.menuText}
            className={horizontalItemLayout ? classes.menuIconSmall : classes.menuIcon}
          >
            {menuOption.menuIcon}
          </div>
        </ListItemIcon>
        <ListItemText className={classes.listItemText} onClick={e => e.preventDefault()}>
          <Typography className={`${horizontalItemLayout && classes.listItemTextTypographySmall} ${classes.listItemTextTypography}`}>
            {menuOption.menuText}
          </Typography>
          {menuOption.menuItems && (
            <ListItemSecondaryAction>
              <Hidden lgUp implementation="js">
                <Checkbox
                  checked={menuOptionActive}
                  icon={<ExpandIcon className={classes.expandCollapseIcon} />}
                  checkedIcon={<CollapseIcon className={classes.expandCollapseIcon} />}
                  onChange={(e, checked) => {
                    this.expandOrCollapseSubMenu(e, menuOption, depth, checked);
                  }}
                />
              </Hidden>
            </ListItemSecondaryAction>
          )}
          {menuOption.isNew && <span className={classes.isNewMarker}>NEW</span>}
          {menuOption.notification
            && (
            <span className={classes.notificationMarker}>
              <RoundIcon iconType={IconTypes.Warn} iconSize={IconSizes.Small} />
            </span>
            )}
        </ListItemText>
      </ListItem>
    );

    if (!isDesktopNav || (isDesktopNav && depth !== 0)) {
      return (
        <Slide
          in={(mobileOpen && !isDesktopNav) || isDesktopNav}
          timeout={
            (isDesktopNav && depth !== 0) || (!isDesktopNav && depth === 0)
              ? DRAWER_TRANSITION_MS
              : 0
          }
          direction="right"
        >
          {listItem}
        </Slide>
      );
    }
    return listItem;
  };

  getListForMenuItems = (menuItems, depth) => {
    const { classes, width } = this.props;
    const { activeMenuOptions, activeListItems } = this.state;
    const isDesktopNav = isWidthUp('lg', width);

    return (
      <List
        className={[classes.list, isDesktopNav && depth !== 0 ? classes.listDesktopSub : '']
          .join(' ')
          .trim()}
      >
        {menuItems.map((menuOption, index) => (
          <Fragment key={menuOption.menuText}>
            {this.getListItemForMenuOption(menuOption, depth, index)}
            {menuOption.menuItems && (
              <Fragment>
                <Hidden mdDown implementation="js">
                  <Popper
                    open={Boolean(activeMenuOptions[depth] === menuOption)}
                    anchorEl={activeListItems[depth]}
                    placement="right-start"
                    className={classes.subMenuPopper}
                  >
                    <Slide
                      in={Boolean(activeMenuOptions[depth] === menuOption)}
                      direction="right"
                      timeout={DRAWER_TRANSITION_MS}
                    >
                      <div>
                        <Fade
                          in={Boolean(activeMenuOptions[depth] === menuOption)}
                          timeout={DRAWER_TRANSITION_MS * 2}
                        >
                          <div>
                            <ClickAwayListener onClickAway={this.handleClickAway}>
                              {this.getListForMenuItems(menuOption.menuItems, depth + 1)}
                            </ClickAwayListener>
                          </div>
                        </Fade>
                      </div>
                    </Slide>
                  </Popper>
                </Hidden>
                <Hidden lgUp implementation="js">
                  <Collapse
                    in={Boolean(activeMenuOptions[depth] === menuOption && menuOption.menuItems)}
                    timeout={DRAWER_TRANSITION_MS}
                  >
                    {this.getListForMenuItems(menuOption.menuItems, depth + 1)}
                  </Collapse>
                </Hidden>
              </Fragment>
            )}
          </Fragment>
        ))}
      </List>
    );
  };

  render() {
    const { classes, menuItems } = this.props;
    const { mobileOpen } = this.state;
    const mainDrawer = this.getListForMenuItems(menuItems, 0);

    if (authenticated() === null) {
      return null;
    }

    return (
      <Fragment>
        <Hidden mdDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            {mainDrawer}
          </Drawer>
        </Hidden>

        <Hidden lgUp implementation="css">
          <Toolbar className={classes.toolbar}>
            <AnimatedBurgerButton value={mobileOpen} onToggle={this.handleDrawerToggle} />
          </Toolbar>
          <Drawer
            anchor="left"
            variant="temporary"
            open={mobileOpen}
            onClose={this.handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              classes: {
                root: classes.mobileModalRoot,
              },
              BackdropProps: {
                classes: {
                  root: classes.mobileBackdropRoot,
                },
              },
            }}
          >
            {mainDrawer}
          </Drawer>
        </Hidden>
      </Fragment>
    );
  }
}

Nav.propTypes = {
  width: PropTypes.string.isRequired,
  classes: PropTypes.shape({}).isRequired,
  location: PropTypes.shape({}).isRequired,
  navigate: PropTypes.func.isRequired,
  menuItems: PropTypes.arrayOf(
    PropTypes.shape({
      menuText: PropTypes.string.isRequired,
      menuIcon: PropTypes.node.isRequired,
      linkTo: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
      isNew: PropTypes.bool,

      menuItems: PropTypes.arrayOf(
        PropTypes.shape({
          menuText: PropTypes.string.isRequired,
          menuIcon: PropTypes.node.isRequired,
          linkTo: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
        }),
      ),
    }),
  ).isRequired,
};

const styles = theme => ({
  toolbar: {
    top: TOOLBAR_NAV_TOP,
    width: '100%',
    minHeight: TOOLBAR_HEIGHT,
    position: 'fixed',
    backgroundColor: theme.palette.primary.main,
    zIndex: 4, // To work with react-big-calendar
  },
  drawer: {
    [theme.breakpoints.up('md')]: {
      width: DRAWER_WIDTH,
      flexShrink: 0,
    },
  },
  drawerPaper: {
    overflow: 'hidden',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    width: DRAWER_WIDTH,
    top: TOOLBAR_NAV_TOP,
    paddingTop: 25,
    boxShadow: theme.shadows[12],
    [theme.breakpoints.down('md')]: {
      top: 120,
      width: '100%',
      height: 'auto',
      backgroundColor: 'transparent',
      boxShadow: 'none',
    },
  },
  mobileModalRoot: {
    top: TOOLBAR_NAV_TOP + TOOLBAR_HEIGHT,
  },
  mobileBackdropRoot: {
    top: TOOLBAR_NAV_TOP,
  },
  menuButton: {
    color: theme.palette.common.white,
  },
  list: {
    [theme.breakpoints.up('lg')]: {
      width: DRAWER_WIDTH,
      backgroundColor: theme.palette.primary.main,
    },
    padding: 0,
  },
  listDesktopSub: {
    boxShadow: theme.shadows[6],
    width: 196,
  },
  listItem: {
    width: 'auto',
    paddingRight: theme.spacing(4), // to cancel the secondary action
    paddingTop: theme.spacing(3.5),
    paddingBottom: theme.spacing(5),
    color: theme.palette.common.white,

    [theme.breakpoints.up('lg')]: {
      flexDirection: 'column',
    },

    [theme.breakpoints.down('md')]: {
      backgroundColor: theme.palette.primary.main,
      margin: theme.spacing(4),
    },

    '&:hover, &:focus': {
      backgroundColor: theme.palette.primary.light,
      transition: `0.3s all ${theme.transitions.easing.easeInOut}`,
      [theme.breakpoints.up('lg')]: {
        textDecoration: 'underline',
      },
    },
  },
  listItemSelected: {
    '& > div > div': {
      backgroundColor: 'white',
    },
    '& svg': {
      color: `${theme.palette.secondary.main} !important`,
    },
    '&:hover, &:active, &:focus': {
      '& svg': {
        color: `${theme.palette.secondary.main} !important`,
      },
    },
  },
  listItemSelectedHorizontal: {
    backgroundColor: theme.palette.primary.light,
  },
  listItemHorizontal: {
    flexDirection: 'row',
    padding: '15px 20px 19px',
  },
  listItemMobileNonRoot: {
    margin: '0 16px',
  },
  listItemMobileHasSub: {
    marginBottom: 0,
  },
  listItemMobileNonRootDepth1: {
    paddingLeft: theme.spacing(13),
  },
  listItemMobileNonRootDepth2: {
    paddingLeft: theme.spacing(26),
  },
  // For staggered mobile menu item slide entry times
  listItemIndex0: {
    transitionDelay: '0s !important',
  },
  listItemIndex1: {
    transitionDelay: '0.15s !important',
  },
  listItemIndex2: {
    transitionDelay: '0.3s !important',
  },
  listItemIndex3: {
    transitionDelay: '0.45s !important',
  },
  listItemIndex4: {
    transitionDelay: '0.6s !important',
  },
  listItemIndex5: {
    transitionDelay: '0.75s !important',
  },
  listItemIcon: {
    [theme.breakpoints.up('lg')]: {
      margin: 'auto',
    },
  },
  listItemIconSmall: {
    [theme.breakpoints.up('lg')]: {
      margin: 'auto',
      minWidth: 40,
    },
  },
  menuIcon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 40,
    height: 40,
    margin: 'auto',
    backgroundColor: theme.palette.primary.light,
    borderRadius: 6,
    marginBottom: 5,
    '& svg': {
      color: 'white',
    },
  },
  menuIconSmall: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& svg': {
      width: 20,
      color: 'white',
    },
  },
  listItemText: {
    padding: 0,
    margin: 0,
    width: '100%',
  },
  listItemTextTypography: {
    fontSize: 12,
    [theme.breakpoints.up('md')]: {
      fontSize: '0.875rem',
    },
    [theme.breakpoints.up('lg')]: {
      textAlign: 'center',
    },
    color: theme.palette.common.white,
    fontWeight: theme.typography.fontWeightHeavy,
  },
  listItemTextTypographySmall: {
    [theme.breakpoints.up('lg')]: {
      textAlign: 'left',
    },
  },
  isNewMarker: {
    ...theme.typography.subtitle2,
    fontWeight: theme.typography.fontWeightHeavy,
    position: 'absolute',
    top: 9,
    right: 10,
    padding: '2px 5px',
    display: 'block',
    color: theme.palette.common.white,
    background: theme.palette.warn.main,
    textTransform: 'uppercase',
    borderRadius: 3,
    boxShadow: theme.shadows[3],
  },
  notificationMarker: {
    position: 'absolute',
    top: 9,
    right: 15,
  },
  expandCollapseIcon: {
    color: theme.palette.common.white,
  },
  subMenuPopper: {
    zIndex: 3,
  },
});

export default withRouter(
  withStyles(styles, { withTheme: true })(withWidth({ resizeInterval: 50 })(Nav)),
);
