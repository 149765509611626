export const GET_PRETOTYPE = 'GET_PRETOTYPE';
export const GET_PRETOTYPE_SUCCESS = 'GET_PRETOTYPE_SUCCESS';
export const GET_PRETOTYPE_FAIL = 'GET_PRETOTYPE_FAIL';
export const ACK_PRETOTYPE = 'ACK_PRETOTYPE';
export const ACK_PRETOTYPE_SUCCESS = 'ACK_PRETOTYPE_SUCCESS';
export const ACK_PRETOTYPE_FAIL = 'ACK_PRETOTYPE_FAIL';

export function getPretotype(type, jurisdictions) {
  return {
    types: [
      GET_PRETOTYPE,
      GET_PRETOTYPE_SUCCESS,
      GET_PRETOTYPE_FAIL,
    ],
    payload: {
      client: 'notifyClient',
      request: {
        url: `/pretotype/${type}?jurisdictions=${jurisdictions}`,
      },
    },
  };
}

export function ackPretotype(id, data) {
  return {
    types: [
      ACK_PRETOTYPE,
      ACK_PRETOTYPE_SUCCESS,
      ACK_PRETOTYPE_FAIL,
    ],
    payload: {
      client: 'notifyClient',
      request: {
        method: 'post',
        url: '/pretotype/ack',
        data: { id, data },
      },
    },
  };
}
