import React, { Fragment, Component } from 'react';
import PropTypes from 'prop-types';

import Popper from '@material-ui/core/Popper';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Fade from '@material-ui/core/Fade';
import Checkbox from '@material-ui/core/Checkbox';
import Slide from '@material-ui/core/Slide';

const POPPER_TRANSITION_MS = 350;

class ToggledPopper extends Component {
  state = {
    anchorElRef: null,
  };

  render() {
    const {
      iconFunc, children, checkboxProps, popperProps,
    } = this.props;

    const { anchorElRef } = this.state;
    return (
      <Fragment>
        <Checkbox
          checked={Boolean(anchorElRef)}
          onChange={(event, checked) => {
            this.setState({ anchorElRef: checked ? event.target : null });
          }}
          icon={iconFunc(false)}
          checkedIcon={iconFunc(true)}
          {...checkboxProps}
        />
        <Popper
          disablePortal
          open={Boolean(anchorElRef)}
          transition={Boolean(anchorElRef)}
          anchorEl={anchorElRef}
          onClose={() => this.setState({ anchorElRef: null })}
          {...popperProps}
        >
          <ClickAwayListener
            onClickAway={(event) => {
              // if not unchecking the checkbox
              if (event.target !== anchorElRef) this.setState({ anchorElRef: null });
            }}
          >
            {/* div required so transitions work and don't have other impact */}
            <Slide
              in
              direction="up"
              timeout={POPPER_TRANSITION_MS}
            >
              <div>
                <Fade in timeout={POPPER_TRANSITION_MS * 3}>
                  <div>{children}</div>
                </Fade>
              </div>
            </Slide>
          </ClickAwayListener>
        </Popper>
      </Fragment>
    );
  }
}

ToggledPopper.propTypes = {
  iconFunc: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.node, PropTypes.object]).isRequired,
  checkboxProps: PropTypes.shape({}),
  popperProps: PropTypes.shape({}),
};

ToggledPopper.defaultProps = {
  checkboxProps: {},
  popperProps: {},
};

export default ToggledPopper;
