export const GET_SETTLEMENTS = 'GET_SETTLEMENTS';
export const GET_SETTLEMENTS_SUCCESS = 'GET_SETTLEMENTS_SUCCESS';
export const GET_SETTLEMENTS_FAIL = 'GET_SETTLEMENTS_FAIL';

export function getSettlements() {
  return {
    types: [GET_SETTLEMENTS, GET_SETTLEMENTS_SUCCESS, GET_SETTLEMENTS_FAIL],
    payload: {
      request: {
        url: '/summary?settlement_calendar',
      },
    },
  };
}
