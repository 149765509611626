export const RETRIEVE_INVOICE = 'RETRIEVE_INVOICE';
export const RETRIEVE_INVOICE_SUCCESS = 'RETRIEVE_INVOICE_SUCCESS';
export const RETRIEVE_INVOICE_FAIL = 'RETRIEVE_INVOICE_FAIL';

export const DOWNLOAD_INVOICE = 'DOWNLOAD_INVOICE';
export const DOWNLOAD_INVOICE_SUCCESS = 'DOWNLOAD_INVOICE_SUCCESS';
export const DOWNLOAD_INVOICE_FAIL = 'DOWNLOAD_INVOICE_FAIL';

export const PREPARE_INVOICE = 'PREPARE_INVOICE';
export const PREPARE_INVOICE_SUCCESS = 'PREPARE_INVOICE_SUCCESS';
export const PREPARE_INVOICE_FAIL = 'PREPARE_INVOICE_FAIL';

export const GET_INVOICE_DOWNLOAD_LINK = 'GET_INVOICE_DOWNLOAD_LINK';
export const GET_INVOICE_DOWNLOAD_LINK_SUCCESS = 'GET_INVOICE_DOWNLOAD_LINK_SUCCESS';
export const GET_INVOICE_DOWNLOAD_LINK_FAIL = 'GET_INVOICE_DOWNLOAD_LINK_FAIL';

export const GET_INVOICE_DOWNLOAD_STATUS = 'GET_INVOICE_DOWNLOAD_STATUS';
export const GET_INVOICE_DOWNLOAD_STATUS_SUCCESS = 'GET_INVOICE_DOWNLOAD_STATUS_SUCCESS';
export const GET_INVOICE_DOWNLOAD_STATUS_FAIL = 'GET_INVOICE_DOWNLOAD_STATUS_FAIL';

export const DOWNLOAD_INVOICE_DOCHUB = 'DOWNLOAD_INVOICE_DOCHUB';
export const DOWNLOAD_INVOICE_DOCHUB_SUCCESS = 'DOWNLOAD_INVOICE_DOCHUB_SUCCESS';
export const DOWNLOAD_INVOICE_DOCHUB_FAIL = 'DOWNLOAD_INVOICE_DOCHUB_FAIL';

export function retrieveInvoiceByDate(data) {
  return {
    types: [RETRIEVE_INVOICE, RETRIEVE_INVOICE_SUCCESS, RETRIEVE_INVOICE_FAIL],
    payload: {
      client: 'invoiceClient',
      request: {
        url: '/account-management/invoice',
        method: 'post',
        data,
      },
    },
  };
}

export function retrieveInvoiceByReference(ref, type) {
  return {
    types: [RETRIEVE_INVOICE, RETRIEVE_INVOICE_SUCCESS, RETRIEVE_INVOICE_FAIL],
    payload: {
      client: 'invoiceClient',
      request: {
        url: `/account-management/invoice/reference/${ref}/type/${type}`,
        method: 'get',
      },
    },
  };
}

export function downloadInvoice(data) {
  return {
    types: [DOWNLOAD_INVOICE, DOWNLOAD_INVOICE_SUCCESS, DOWNLOAD_INVOICE_FAIL],
    payload: {
      client: 'invoiceClient',
      request: {
        url: '/account-management/invoice/download',
        method: 'post',
        responseType: 'blob',
        data,
      },
    },
  };
}

export function prepareInvoice(data) {
  return {
    types: [PREPARE_INVOICE, PREPARE_INVOICE_SUCCESS, PREPARE_INVOICE_FAIL],
    payload: {
      client: 'invoiceClient',
      request: {
        url: '/account-management/invoice/prepare-invoice',
        method: 'post',
        data,
      },
    },
  };
}

export function getInvoiceDownloadLink(docId) {
  return {
    types: [
      GET_INVOICE_DOWNLOAD_LINK,
      GET_INVOICE_DOWNLOAD_LINK_SUCCESS,
      GET_INVOICE_DOWNLOAD_LINK_FAIL,
    ],
    payload: {
      client: 'invoiceClient',
      request: {
        url: `/account-management/invoice/download-link/${docId}`,
        method: 'get',
      },
    },
  };
}

export function getInvoiceDownloadStatus(docId) {
  return {
    types: [
      GET_INVOICE_DOWNLOAD_STATUS,
      GET_INVOICE_DOWNLOAD_STATUS_SUCCESS,
      GET_INVOICE_DOWNLOAD_STATUS_FAIL,
    ],
    payload: {
      client: 'invoiceClient',
      request: {
        url: `/account-management/invoice/document/${docId}/status`,
        method: 'get',
      },
    },
  };
}

export function downloadInvoiceDochub(docId) {
  return {
    types: [
      DOWNLOAD_INVOICE_DOCHUB,
      DOWNLOAD_INVOICE_DOCHUB_SUCCESS,
      DOWNLOAD_INVOICE_DOCHUB_FAIL,
    ],
    payload: {
      client: 'invoiceClient',
      request: {
        url: `/account-management/invoice/document/${docId}`,
        method: 'get',
        responseType: 'blob',
      },
    },
  };
}
