import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { loadErrorPage } from '../reducers/errorPage';
import GenericError, { errorCodes } from './genericError';

export const getErrorComponent = (status) => {
  const error = errorCodes.find(err => err.code === status);
  return <GenericError error={error} />;
};

export class ErrorPageBase extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    dispatch: PropTypes.func.isRequired,
    errorPage: PropTypes.shape({}),
  }

  static defaultProps = {
    errorPage: {},
  }

  static getDerivedStateFromError() {
    return null;
  }

  componentDidCatch(error) {
    const { dispatch } = this.props;
    dispatch(loadErrorPage({ error }));
  }

  render() {
    const { children, errorPage } = this.props;

    if (errorPage.hasError) {
      const ErrorComponent = () => getErrorComponent(errorPage.statusCode);
      return (
        <ErrorComponent />
      );
    }

    return children;
  }
}


const mapStateToProps = state => ({
  errorPage: state.errorPage,
});

export default compose(
  connect(mapStateToProps),
)(ErrorPageBase);
