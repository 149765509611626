import {
  GET_NOTIFICATIONS,
  GET_NOTIFICATIONS_SUCCESS,
  GET_NOTIFICATIONS_FAIL,
  ACK_NOTIFICATION,
  ACK_NOTIFICATION_SUCCESS,
  ACK_NOTIFICATION_FAIL,
} from '../actions/notifications';

export const initialState = {
  data: null,
  loading: false,
  error: null,
  ack_notification: { loading: false, error: null },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_NOTIFICATIONS:
      return {
        ...state,
        ...{
          data: null,
          error: null,
          loading: true,
        },
      };
    case GET_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        ...{
          data: action.payload,
          error: null,
          loading: false,
        },
      };
    case GET_NOTIFICATIONS_FAIL:
      return {
        ...state,
        ...{
          data: null,
          error: action.error,
          loading: false,
        },
      };
    case ACK_NOTIFICATION:
      return {
        ...state,
        ...{ ack_notification: { error: null, loading: true } },
      };
    case ACK_NOTIFICATION_SUCCESS:
      return {
        ...state,
        ...{ ack_notification: { error: null, loading: false } },
      };
    case ACK_NOTIFICATION_FAIL:
      return {
        ...state,
        ...{ ack_notification: { error: true, loading: false } },
      };
    default:
      return state;
  }
};
