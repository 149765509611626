export const GET_ANNOUNCEMENTS = 'GET_ANNOUNCEMENTS';
export const GET_ANNOUNCEMENTS_SUCCESS = 'GET_ANNOUNCEMENTS_SUCCESS';
export const GET_ANNOUNCEMENTS_FAIL = 'GET_ANNOUNCEMENTS_FAIL';

export function getAnnouncements() {
  return {
    types: [GET_ANNOUNCEMENTS, GET_ANNOUNCEMENTS_SUCCESS, GET_ANNOUNCEMENTS_FAIL],
    payload: {
      request: {
        url: '/summary?announcements',
      },
    },
  };
}
