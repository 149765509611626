import React from 'react';
import PropTypes from 'prop-types';
import MuiPaper from '@material-ui/core/Paper';
import withStyles from '@material-ui/core/styles/withStyles';

const Paper = ({
  children, className, classes, theme, ...rest
}) => (
  <MuiPaper className={[className, classes.paper].join(' ').trim()} {...rest}>
    {children}
  </MuiPaper>
);

Paper.propTypes = {
  theme: PropTypes.shape({}).isRequired,
  className: PropTypes.string,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  children: PropTypes.node,
};

Paper.defaultProps = {
  className: null,
  children: null,
};

const style = theme => ({
  paper: {
    position: 'relative',
    borderRadius: 4,
    padding: theme.spacing(5),
    transition: `0.3s box-shadow ${theme.transitions.easing.easeInOut}`,
    '&:hover': {
      boxShadow: theme.shadows[6],
    },
  },
});

export default withStyles(style, { withTheme: true })(Paper);
