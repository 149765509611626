export const GET_SETTLEMENTS_TODAY = 'GET_SETTLEMENTS_TODAY';
export const GET_SETTLEMENTS_TODAY_SUCCESS = 'GET_SETTLEMENTS_TODAY_SUCCESS';
export const GET_SETTLEMENTS_TODAY_FAIL = 'GET_SETTLEMENTS_TODAY_FAIL';

export function getSettlementsToday() {
  return {
    types: [GET_SETTLEMENTS_TODAY, GET_SETTLEMENTS_TODAY_SUCCESS, GET_SETTLEMENTS_TODAY_FAIL],
    payload: {
      request: {
        url: '/summary?todays_settlements',
      },
    },
  };
}
